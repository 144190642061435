import dayjs from 'dayjs';
import extendedDayjs from './dayjs';

export const MILLISECONDS_A_SECOND = 1000;
export const SECONDS_A_MINUTE = 60;

/**
 * Formats duration.
 * @param {number} duration Duration in second.
 * @returns {string} Formatted time string.
 */
export const formatDuration = (duration: number): string => {
	return extendedDayjs.duration(duration * MILLISECONDS_A_SECOND).format('HH:mm:ss');
};

/**
 * Formats date.
 * @param {Date} date Date value.
 * @returns {string} Formatted date time string.
 */
export const formatDate = (date: Date): string => {
	return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
};

/**
 * Formats seconds to a human-friendy string.
 * @example secondsToMinutes(3600); // 60min
 * @param seconds duration in seconds
 * @returns humanised duration with suffix
 */
export const humaniseSeconds = (seconds: number): string => {
	return (seconds / SECONDS_A_MINUTE).toString() + 'min';
};