import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Divider } from 'antd';
import Timer from '@/components/Timer';
import classnames from 'classnames';
import styles from './BasicLayout.less';
import SelectLang from '@/components/SelectLang';

interface IBasicLayoutProps {
	assessmentName?: string;
	currentQuestionIndex?: number;
	questionCount?: number;
	timeLimit?: number;
	showTimer?: boolean;
	onTimeUp?: () => void;
	className?: string;
	usedTime?: number;
}

const BasicLayout: React.FC<IBasicLayoutProps> = ({
	assessmentName,
	currentQuestionIndex,
	questionCount,
	timeLimit,
	onTimeUp,
	children,
	showTimer,
	usedTime,
}) => {
	return (
		<>
			<div className={classnames(styles.header)}>
				<div className={classnames(styles.leftHeader)}>
					<img src="/icons/sigma-whiteLogo.svg" alt="logo" />
					<Divider type="vertical" className={styles.divider} />
					{assessmentName && (
						<span className={classnames('font-size-20', styles.assessmentName)}>
							{assessmentName}
						</span>
					)}
				</div>

				<div className={classnames('display-flex', 'align-items-center', styles.rightHeader)}>
					{currentQuestionIndex && (
						<div className={showTimer ? styles.visible : styles.notVisible}>
							<div
								className={classnames('display-flex', 'align-items-center', styles.totalQuestionTimerContainer)}
							>
								<div className={classnames('display-flex', 'align-items-center', 'font-size-16')}>
									<img src="/icons/question_count_white.svg" className={styles.icon}
										alt="question count"
									/>
									<span className={styles.messages}>
										<span className={styles.message}>
											<FormattedMessage id="question.questionCount" />
										</span>
										{currentQuestionIndex}/{questionCount}
									</span>
								</div>
								{timeLimit && (
									<>
										<Divider type="vertical" className={styles.divider} />
										<div
											className={classnames('display-flex', 'align-items-center', 'font-size-16')}
										>
											<img src="/icons/clock_white.svg" className={styles.icon} alt="clock" />
											<span>
												<span className={styles.message}>
													<FormattedMessage id="question.timer" />
												</span>
												<Timer initialTime={timeLimit} onTimeUp={onTimeUp} usedTime={usedTime} />
											</span>
										</div>
									</>
								)}
							</div>
						</div>
					)}
					<SelectLang />
				</div>
			</div>
			<div className={styles.content}>
				<div>
					{children}
				</div>
			</div>
			<footer className={styles.footer}>
				Powered by Jobpin & JR Academy
			</footer>
		</>
	);
};

export default BasicLayout;
