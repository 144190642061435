import { isEmpty } from 'lodash';
import type { FormInstance, NamePath } from 'rc-field-form/lib/interface';

/**
 * Checks whether the given antd form has error.
 * @param form form instance
 * @param nameList fields to be checked. If null, all fields will be ckecked
 * @returns true if has error
 */
export const hasError = (form: FormInstance, nameList?: NamePath[]): boolean => (
	form.getFieldsError(nameList).some(field => !isEmpty(field.errors))
);

/**
 * Checks whether the given antd form fields are filled.
 * @param form form instance
 * @param nameList fields to be checked. If null, all fields will be ckecked
 * @returns true if all given fields are filled
 */
export const isFieldsFilled = (form: FormInstance, nameList: NamePath[] | true = true): boolean => (
	form.getFieldsValue(nameList)? !Object.values(form.getFieldsValue(nameList)).some(value => value === undefined) : false
);