// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".correctnessTag-SaX4cV {\n  padding: 3px 18px;\n  margin: 0 5px;\n  border-radius: 6px;\n  border: solid 4px;\n  font-weight: 600;\n}\n.correctnessTag-SaX4cV.correct-RhrpkD {\n  border-color: #83cc99;\n  color: #83cc99;\n}\n.correctnessTag-SaX4cV.incorrect--e2Fak {\n  border-color: #fe8986;\n  color: #fe8986;\n}\n", "",{"version":3,"sources":["webpack://src/pages/Assessment/Components/CorrectnessTag/CorrectnessTag.less"],"names":[],"mappings":"AAEA;EACC,iBAAA;EACA,aAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;AADD;AAGC;EACC,qBAAA;EACA,cAAA;AADF;AAIC;EACC,qBAAA;EACA,cAAA;AAFF","sourcesContent":["@import '~@/utils/utils.less';\n\n.correctnessTag {\n\tpadding: 3px 18px;\n\tmargin: 0 5px;\n\tborder-radius: 6px;\n\tborder: solid 4px;\n\tfont-weight: 600;\n\n\t&.correct {\n\t\tborder-color: @green;\n\t\tcolor: @green;\n\t}\n\n\t&.incorrect {\n\t\tborder-color: @coral;\n\t\tcolor: @coral;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"correctnessTag": "correctnessTag-SaX4cV",
	"correct": "correct-RhrpkD",
	"incorrect": "incorrect--e2Fak"
};
export default ___CSS_LOADER_EXPORT___;
