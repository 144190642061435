import React from 'react';
import classnames from 'classnames';
import styles from './Card.less';

interface ICardProps {
	hasShadow?: boolean;
	className?: string;
}

const Card: React.FC<ICardProps> = ({
	hasShadow = false,
	className,
	children,
}) => {
	return (
		<div className={classnames(styles.card, hasShadow && styles.shadow, className)}>
			{children}
		</div>
	);
};

export default Card;