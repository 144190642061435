import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { FormattedMessage, useIntl, IntlShape } from 'react-intl';
import { ProFormText } from '@ant-design/pro-form';
import { PASSWORD_REGEX } from '@/constants/regex';
import type { ValidateStatus } from 'antd/lib/form/FormItem';
import type { Rule } from 'antd/lib/form';

interface IPasswordProps {
	isResetPassword?: boolean;
	token?: string | null;
}

const renderPasswordTip = (
	<div className="tip">
		<FormattedMessage id="register.password.error.passwordFormat" />
	</div>
);

const Password: React.FC<IPasswordProps> = ({ isResetPassword, token }) => {
	const intl = useIntl();
	const [passwordError, setPasswordError] = useState<React.ReactElement | string>(renderPasswordTip);
	const [passwordValidateStatus, setPasswordValidateStatus] = useState<ValidateStatus>('');
	const dispatch = useDispatch();
	const passwordValidator = (intl: IntlShape) => async(rule: Rule, value: string) => {
		if (!value) {
			setPasswordValidateStatus('error');
			setPasswordError(<FormattedMessage id="register.password.error.emptyPassword" />);
			return Promise.reject();
		}
		if (value.length < 6 || value.length > 30) {
			setPasswordValidateStatus('error');
			setPasswordError(<FormattedMessage id="register.password.error.passwordLength" />);
			return Promise.reject();
		}
		if (!PASSWORD_REGEX.test(value)) {
			setPasswordValidateStatus('error');
			setPasswordError(<FormattedMessage id="register.password.error.passwordCase" />);
			return Promise.reject();
		}
		if (isResetPassword && token) {
			const isSame = await dispatch({
				type: 'user/checkPasswordMatchOldPassword',
				payload: {
					password: value,
					confirmedPassword: value,
					token
				}
			});
			if (isSame) {
				setPasswordValidateStatus('error');
				setPasswordError(<FormattedMessage id="resetPassword.password.error.usedPassword" />);
				return Promise.reject();
			}
		}

		setPasswordValidateStatus('success');
		setPasswordError(renderPasswordTip);
		return Promise.resolve();
	};

	return (
		<ProFormText.Password
			name="password"
			fieldProps={{
				size: 'large',
				type: 'password',
				allowClear: false,
				iconRender: visible => (
					<span>
						{visible
							? <img src="/icons/eye_blue_reverse.svg" alt="visible" />
							: <img src="/icons/eye_blue.svg" alt="invisible" />
						}
					</span>
				),
			}}
			placeholder={intl.formatMessage({ id: 'register.password.placeholder' })}
			rules={[{ validator: passwordValidator(intl) }]}
			help={passwordError}
			validateTrigger={['onBlur']}
			validateStatus={passwordValidateStatus}
		/>
	);
};

export default connect()(Password);