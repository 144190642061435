import React, { useEffect, useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line
import { Radio, Checkbox, Space } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { getQuestion, setCurrentQuestionIndex, updateAnswerRecords, saveCurrentSelectedOptions, completeQuestion, updateQuestionEndTime, getQuestionReview } from '@/store/question/actions';
import { setInvitation, submitAssessment } from '@/store/invitation/actions';
import { updateAssessmentRecord, resumeAssessmentRecord, removeAssessmentRecord } from '@/utils/assessmentRecord';
import { EQuestionType, EQuestionTypeText } from '@/constants/question';
import { containSameElements, disableBackButton } from '@/utils/utils';
import classnames from 'classnames';
import type { RadioChangeEvent } from 'antd';
import type { IActionCreator, IConnectState } from '@/interfaces/redux';
import type { IInvitationBase } from '@/interfaces/invitation';
import { EInvitationStatus } from '@/constants/invitation';
import Button from '@/components/Button';
import ConfirmModal from '@/components/ConfirmModal';
import styles from './Assessment.less';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import BasicLayout from '@/layouts/BasicLayout';
import CountDownTimer from '@/pages/Assessment/Components/CountDownTimer/CountDownTimer';
import { updateQuestionEndTimeStorage, resumeQuestionTimer } from '@/utils/questionEndTimeStorage';
import CorrectnessTag from './Components/CorrectnessTag';
import { getLocalStorage } from '@/utils/storage';
import { useHotkeys } from 'react-hotkeys-hook';
import { ILoadingStatus } from '@/interfaces/loading';
import { IQuestionBase, IGetQuestionRequest, IQuestionOptions } from '@/interfaces/question';
import { getCurrentInvitation } from '@/utils/invitation';
import { TimeOutModal } from './Components/TimeOutModal/TimeOutModal';
import QuestionAnalysis from '@/pages/Assessment/Components/QuestionAnalysis';

interface IAssessmentProps {
	invitationId?: string;
	invitationStatus?: EInvitationStatus;
	questionCount?: number;
	timeLimit?: number;
	assessmentName?: string,
	questions?: Record<number, IQuestionBase>;
	answerRecords?: Record<number, number[]>;
	currentQuestionIndex: number;
	loadingStatus?: ILoadingStatus;
	getQuestion: IActionCreator<IGetQuestionRequest>;
	getQuestionReview: IActionCreator<IGetQuestionRequest>;
	setCurrentQuestionIndex: IActionCreator<number>;
	setInvitation: IActionCreator<IInvitationBase>;
	updateAnswerRecords: IActionCreator<Record<number, number[] | undefined>>;
	submitAssessment: IActionCreator<string>;
	saveCurrentSelectedOptions: IActionCreator<never>,
	timeLimitEachQuestion?: number,
	completedQuestions: Array<string | undefined>,
	completeQuestion: IActionCreator<string>,
	questionEndTime?: number,
}

const Assessment: React.FC<IAssessmentProps> = (props) => {
	const history = useHistory();
	const intl = useIntl();
	const invitation = getCurrentInvitation();
	const { invitationId, invitationStatus, assessmentName, currentQuestionIndex, questionEndTime, answerRecords, timeLimit, questions, getQuestion, getQuestionReview, setCurrentQuestionIndex, setInvitation, updateAnswerRecords, submitAssessment,
		saveCurrentSelectedOptions, completeQuestion } = props;
	const questionCount = invitation?invitation.assessment.questionCount : props.questionCount;
	const timeLimitEachQuestion = invitation?invitation.assessment.timeLimitEachQuestion : props.timeLimitEachQuestion;
	const currentQuestion = questions?.[currentQuestionIndex];
	const selectedOptions = answerRecords?.[currentQuestionIndex];
	const originalSelectedOptions = useRef(selectedOptions);
	const [reachableQuestionIndex, setReachableQuestionIndex] = useState<number>(0);
	const [currentAnswerCorrectOrIncorrect, setCurrentAnswerCorrectOrIncorrect] = useState<boolean | undefined>(currentQuestion?.questionReview?.answer);
	const [modalStatus, setModalStatus] = useState<boolean>(false);
	const [radioSelectedOptions, setRadioSelectedOptions] = useState<number | null>();
	const [checkBoxSelectedOptions, setCheckBoxSelectedOptions] = useState<CheckboxValueType[] | null>();
	const [editAnswer, setEditAnswer] = useState<boolean>(false);
	const usedTime: number | undefined = currentQuestion?.usedTime;

	document.title = `Assessment: ${assessmentName}`;

	useEffect(() => {
		const selectedOptionsBase = questions?.[currentQuestionIndex];
		const selectedOptionsValues = selectedOptionsBase?.questionOptions?.map((item) => item.id);
		const selectedOptionsValue = selectedOptionsValues?.indexOf(selectedOptions?.[0] || 0);
		!!selectedOptions ? setRadioSelectedOptions(selectedOptionsValue) : setRadioSelectedOptions(null);
	}, [selectedOptions]);

	useEffect(() => {
		if (isReview() && !isUndefined(invitationId)) {
			if (!isUndefined(questions?.[currentQuestionIndex])) {
				const selectedOption = questions?.[currentQuestionIndex].questionReview?.selectedOption;
				const answerOption = questions?.[currentQuestionIndex]?.questionOptions;
				const selectedIds = selectedOption?.map((item) => item.id);
				const answerOptionIds = answerOption?.map((item) => item.id);

				if (selectedIds) {
					if (questions?.[currentQuestionIndex].questionType === EQuestionType.MULTIPLE_CHOICE ) {
						const selectedIndexs = answerOptionIds?.indexOf(selectedIds[0]);
						setRadioSelectedOptions(selectedIndexs);
					} else {
						const selectedIndexs = selectedIds.map((item) => {
							return answerOptionIds?.indexOf(item);
						} );
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						setCheckBoxSelectedOptions(selectedIndexs);
					}
				}
			}
		}
	}, [questions]);

	const onSelectedOptionChange = (e: RadioChangeEvent) => {
		if (!invitationId) return;
		const questionsFilteredNull = questions?.[currentQuestionIndex].questionOptions.map((item) => item.id);
		const answerOptionsArray: number[] = questionsFilteredNull || [] ;
		const selectedOptions: number[] = [answerOptionsArray[e.target.value]];
		updateAssessmentRecord(invitationId, currentQuestionIndex, null, { [currentQuestionIndex]: selectedOptions });
		updateAnswerRecords({ [currentQuestionIndex]: selectedOptions });
	};

	// if user dose not chose anything, the option record should be undefined
	const handleNullOption = () => {
		if (!invitationId) return;
		updateAnswerRecords(undefined);
	};

	const checkBoxOnChange = (checkedValues: CheckboxValueType[]) => {
		if (!invitationId) return;
		const questionsFilteredNull = questions?.[currentQuestionIndex].questionOptions.map((item) => item.id);
		const answerOptionsArray: number[] = questionsFilteredNull || [] ;
		setCheckBoxSelectedOptions(checkedValues);
		const selectedOptions: number[] = checkedValues.map((selectedOption)=>answerOptionsArray[selectedOption as number]);
		updateAssessmentRecord(invitationId, currentQuestionIndex, null, { [currentQuestionIndex]: selectedOptions });
		updateAnswerRecords({ [currentQuestionIndex]: selectedOptions });
	};

	const switchQuestion = (toIndex: number) => () => {
		if (!invitationId) return;
		// If selected options have changed, save it to backend.
		if (!containSameElements(originalSelectedOptions.current, selectedOptions) && !isReview()) {
			saveCurrentSelectedOptions();
		}
		if (isUndefined(selectedOptions) && !isReview()) {
			handleNullOption();
			saveCurrentSelectedOptions();
		}
		setCheckBoxSelectedOptions([]);
		originalSelectedOptions.current = answerRecords?.[toIndex];
		updateAssessmentRecord(invitationId, toIndex, currentQuestion?.questionId);
		setCurrentQuestionIndex(toIndex);
		updateCurrentFinishedQuestionIndex(toIndex);
	};
	const updateCurrentFinishedQuestionIndex = (currentIndex: number) => {
		if (invitationStatus === EInvitationStatus.STARTED && currentIndex === reachableQuestionIndex + 1) {
			setReachableQuestionIndex(previousValue => previousValue + 1);
		}
	};
	const isReview = () => {
		return (invitationStatus === EInvitationStatus.REVIEWING);
	};

	const handleTimeIsUp = () => {
		!!questionCount && (currentQuestionIndex === questionCount - 1) ? confirmSubmit() : toNextQuestion();
	};

	const toNextQuestion = () => {
		if (isReview()) {
			switchQuestion(currentQuestionIndex + 1)();
			return;
		}
		completeQuestion(currentQuestion?.questionId);
		setEditAnswer(false);
		switchQuestion(currentQuestionIndex + 1)();
		if (!isReview()) {
			invitationId && timeLimitEachQuestion && updateQuestionEndTimeStorage(invitationId,
				Date.now() + timeLimitEachQuestion*1000);
		}
	};

	useEffect(() => {
		disableBackButton();
	}, []);

	const showTimeOutModal = () => {
		setModalStatus(true);
	};

	const hideTimeOutModal = () => {
		setModalStatus(false);
	};
	// Resumes invitation from local storage.
	const resumeInvitation = useCallback((): void => {
		const resumedInvitation = getLocalStorage<IInvitationBase>('invitation');
		if (!!resumedInvitation) {
			setInvitation(resumedInvitation);
		}
	}, [setInvitation]);

	const onFinishClick = (): void => {
		setEditAnswer(true);
		ConfirmModal({
			content: intl.formatMessage({ id: 'assessment.submission.title' }),
			okText: intl.formatMessage({ id: 'assessment.submission.confirm' }),
			cancelText: intl.formatMessage({ id: 'assessment.submission.review' }),
			onOk: confirmSubmit,
		});
	};

	const confirmSubmit = (): Promise<void> => new Promise((resolve, reject) => {
		if (!invitationId) {
			reject();
			return;
		}
		if (isUndefined(selectedOptions)) {
			handleNullOption();
		}
		completeQuestion(currentQuestion?.questionId);
		updateAssessmentRecord(invitationId, currentQuestionIndex, currentQuestion?.questionId);
		if (!isReview()) {
			submitAssessment(invitationId, () => {
				resolve();
				history.push('/submission');
			});
		} else {
			history.push('/submission');
		}
		removeAssessmentRecord(invitationId);
	});

	const updateQuestionEndTime = () => (questionEndTime: number) => {
		if (!invitationId) return;
		updateQuestionEndTimeStorage(invitationId, questionEndTime);
	};

	const renderQuestionCountDownTimer = () => (
		timeLimitEachQuestion && questionEndTime &&
		<div className={styles.countDownTimerWrapper}>
			<CountDownTimer
				handleTimeIsUp={handleTimeIsUp}
				timeLimitEachQuestion={timeLimitEachQuestion}
				questionEndTime={questionEndTime}
				updateQuestionEndTime={updateQuestionEndTime()}
				editAnswer={editAnswer}
			/>
		</div>
	);
	/*
	 * If there is no invitation data, resumes it from local storage.
	 * After getting the invitation, resumes assessment record with that invitation ID.
	 */
	useEffect(() => {
		if (!invitationId) {
			resumeInvitation();
			return;
		}
		resumeAssessmentRecord(invitationId);
	}, [invitationId, resumeInvitation]);

	const getAsyncGetQuestionReview = async () => {
		if (!isUndefined(invitationId)) {
			await getQuestionReview({
				invitationId: invitationId,
				questionIndex: currentQuestionIndex,
			});
		}};

	const getAsyncgetQuestion = async () => {
		if (!isUndefined(invitationId)) {
			await getQuestion({
				invitationId: invitationId,
				questionIndex: currentQuestionIndex,
			});
		}};
	// Gets question from backend if there is no cached question data.
	useEffect(() => {
		setCurrentAnswerCorrectOrIncorrect(currentQuestion?.questionReview?.answer);
		if (!invitationId) return;
		if (!currentQuestion) {
			getAsyncgetQuestion();
		}
		if (invitationStatus === EInvitationStatus.REVIEWING && !currentQuestion?.questionReview) {
			getAsyncGetQuestionReview();
		}
	}, [invitationId, invitationStatus, currentQuestion, currentQuestionIndex, getQuestion]);

	//make timer persistent after refresh
	useEffect((): void => {
		if (!invitationId) return;
		if (timeLimitEachQuestion) {
			resumeQuestionTimer(invitationId).then(isResumed => {
				!isResumed && updateQuestionEndTimeStorage(invitationId, Date.now() + timeLimitEachQuestion*1000);
			});
		}
	}, [invitationId, questionEndTime, timeLimitEachQuestion]);

	const renderQuestion = (): JSX.Element | null => {
		if (!currentQuestion || isUndefined(currentQuestion.questionType)) return null;
		const { questionType, questionTitle, description, attachmentGetDto, questionOptions, caseStudyQuestion } = currentQuestion;

		const renderTitle = () => {
			if (!caseStudyQuestion) {
				return (
					<div>
						<FormattedMessage id="question.title" /> {currentQuestionIndex + 1} : <FormattedMessage id={EQuestionTypeText[questionType]} />
					</div>
				);
			}
			return (
				<div>
					<FormattedMessage id="question.title" /> {currentQuestionIndex + 1} :
					&nbsp;
					<FormattedMessage
						id="question.caseStudy.title"
						values={{
							totalQuestion: caseStudyQuestion.totalQuestionCount,
							subquestionId: 1 + (caseStudyQuestion.subquestionId || 0)
						}}
					/>
				</div>
			);
		};

		const renderCaseStudySubquestionTitle = () => {
			if (!caseStudyQuestion) return;
			return (
				<p className={classnames('font-size-18')}>
					<span className={styles.subquestionTitle}>
						<FormattedMessage
							id="question.caseStudy.subquestion.title"
							values={{
								subquestionId: 1 + (caseStudyQuestion.subquestionId || 0)
							}}
						/>
					</span>
					{questionTitle}
				</p>
			);
		};

		const renderOption = (index: number, option: IQuestionOptions, isRadio: boolean) => {
			const isQuestionCorrect: boolean = (currentQuestion.questionReview !== undefined);
			const isOptionCorrect: boolean = (currentQuestion.questionReview !== undefined)
				&& currentQuestion.questionReview.answer;
			const isOptionIncorrect: boolean = (currentQuestion.questionReview !== undefined);

			const optionText: React.ReactElement = (
				<span>
					{!isQuestionCorrect && isOptionCorrect && <span className={classnames('color-green')}>
						<FormattedMessage id="question.review.correctOption" />
					</span>}
					{option.content}
				</span>
			);

			const radioType = (
				<Radio
					key={option.content}
					value={index}
					className={classnames(
						styles.radio,
						isOptionIncorrect && styles.incorrect,
						isOptionCorrect && styles.correct
					)}
					disabled={isReview() || editAnswer}
				>
					{optionText}
				</Radio>
			);

			const checkBoxType = (
				<Checkbox
					key={option.content}
					value={index}
					className={classnames(styles.checkbox, isOptionIncorrect && styles.incorrect, isOptionCorrect && styles.correct)}
					disabled={isReview() || editAnswer}
				>
					{optionText}
				</Checkbox>
			);

			const questionType = isRadio? (radioType) : (checkBoxType);

			return questionType;
		};

		return (
			<div className={styles.card}>
				<div className={styles.questionHeader}>
					<div className={styles.title}>
						{renderTitle()}
					</div>
					{!isReview() && renderQuestionCountDownTimer()}
				</div>
				<div className={styles.questionBody}>
					<div className={styles.left}>
						<h2 className={styles.title}>
							{caseStudyQuestion? caseStudyQuestion.title : questionTitle}
						</h2>
						<div className={styles.description}>
							{caseStudyQuestion? caseStudyQuestion.description : description}
						</div>
						<div className={styles.mobileOnly}>
							{!isEmpty(attachmentGetDto) ? (
								<img className={styles.picture} alt="attachment" src={`${attachmentGetDto.attachmentUrl}`} />
							) : null}
						</div>
						{renderCaseStudySubquestionTitle()}
						<div className={styles.questionTitle}>
							<span>
								<FormattedMessage id="question.option" />
								(<FormattedMessage id={EQuestionTypeText[questionType]} />) :
							</span>
							{isReview() && <CorrectnessTag isCorrect={currentAnswerCorrectOrIncorrect} />}
						</div>
						<div className={styles.options}>
							{questionType === EQuestionType.MULTIPLE_CHOICE ? (
								<Radio.Group value={radioSelectedOptions} onChange={onSelectedOptionChange}>
									<Space direction="vertical">
										{questionOptions.map((option:IQuestionOptions, index:number) =>renderOption(index, option, true))}
									</Space>
								</Radio.Group>
							) : (
								<Checkbox.Group value={checkBoxSelectedOptions || []} onChange={checkBoxOnChange}>
									<Space direction="vertical">
										{questionOptions.map((option:IQuestionOptions, index:number) =>renderOption(index, option, false))}
									</Space>
								</Checkbox.Group>
							)}
						</div>
					</div>
					<div className={styles.right}>
						{!isEmpty(attachmentGetDto) ? (
							<img className={styles.picture} alt="attachment" src={`${attachmentGetDto.attachmentUrl}`} />
						) : null}
					</div>
					{isReview() && !isNull(currentQuestion.questionReview?.questionAnalysis) && <QuestionAnalysis questionAnalysis={currentQuestion.questionReview?.questionAnalysis} />}
				</div>
			</div>
		);
	};
	const hotKeyAction = () => {
		!!questionCount && (currentQuestionIndex === questionCount - 1) ? onFinishClick() : toNextQuestion();
	};

	const ButtonLayout = () => {
		useHotkeys('enter', () => hotKeyAction());
		return (
			<Space size="large" className={styles.buttonContainer}>
				<TimeOutModal
					visible={modalStatus}
					hideModal={hideTimeOutModal}
					submit={confirmSubmit}
				/>
				{!!questionCount && (currentQuestionIndex === questionCount - 1) && (
					<Button
						reverseColor
						onClick={isReview() ? (confirmSubmit) : (onFinishClick)}
						className={styles.finishButton}
					>
						{ isReview() ? <FormattedMessage id="question.return" /> : <FormattedMessage id="question.finish" /> }
					</Button>
				)}
				{!!questionCount && (currentQuestionIndex !== questionCount - 1) && (
					<Button
						reverseColor
						onClick={toNextQuestion}
					>
						<FormattedMessage id="question.next" />
					</Button>
				)}
			</Space>
		);
	};

	return (
		<BasicLayout
			currentQuestionIndex={currentQuestionIndex + 1}
			assessmentName={assessmentName}
			questionCount={questionCount}
			timeLimit={timeLimit}
			onTimeUp={showTimeOutModal}
			showTimer={invitationStatus !== EInvitationStatus.REVIEWING}
			usedTime={usedTime}
		>
			{renderQuestion()}
			<ButtonLayout />
		</BasicLayout>
	);
};

export default connect(({ invitation, question }: IConnectState ) => ({
	invitationId: invitation.invitationId,
	invitationStatus: invitation.status,
	questionCount: invitation?.questionCount,
	timeLimit: invitation.assessment?.duration,
	timeLimitEachQuestion: invitation.assessment?.timeLimitEachQuestion,
	assessmentName: invitation.assessment?.name,
	currentQuestionIndex: question.currentQuestionIndex,
	questions: question.questions,
	answerRecords: question.answerRecords,
	completedQuestions: question.completedQuestions,
	questionEndTime: question.questionEndTime
}), {
	getQuestion,
	getQuestionReview,
	setCurrentQuestionIndex,
	setInvitation,
	updateAnswerRecords,
	submitAssessment,
	saveCurrentSelectedOptions,
	completeQuestion,
	updateQuestionEndTime
})(Assessment);
