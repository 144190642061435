export default {
	'common.complete': '完成',
	'layout.basic.header.logo': 'OA系统',
	'invitation.invalid': '测试邀请已过期',
	'invitation.completed': '您已经完成答题',
	'assessment.cover.header': '测试：{assessmentName}',
	'assessment.cover.start': '开始做题→',
	'assessment.cover.reminder': '*本测试中可能有单题时间限制，请注意答题时间！',
	'assessment.cover.resume': '继续测试',
	'assessment.cover.start.confirm': '您剩余答题次数为{attemptsLeft}次，是否开始答题?',
	'assessment.fields.name': '试卷名称',
	'assessment.fields.description': '试卷描述',
	'assessment.fields.difficulty': '测试难度',
	'assessment.fields.startDate': '开始时间',
	'assessment.fields.endDate': '结束时间',
	'assessment.fields.duration': '预计用时',
	'assessment.fields.timeLimitEachQuestion': '每道题允许答题时间',
	'assessment.fields.trialsLimit': '答题次数限制',
	'assessment.fields.questionCount': '总计题数',
	'assessment.fields.questionCount.default': '测试题库中的所有题目',
	'assessment.fields.totalPoints': '总分值',
	'assessment.fields.unlimited': '不限',
	'assessment.questionTimeLimit': '限时题目',
	'assessment.submission.title': '您确认要提交试卷吗？提交后不可修改',
	'assessment.submission.review': '再检查一下',
	'assessment.submission.confirm': '确认提交',
	'assessment.timeout.title': '该测试已过期',
	'assessment.timeout.company.contact': '若有问题，请连系：',
	'assessment.timeout.confirm': '确认并返回',
	'assessment.timeout.customer.contact': '更多问题请联系客服邮件：',
	'assessment.link.expired': '该测试链接已过期',
	'submission.title': '你的答题卡',
	'submissionCard.correctRate': '答对率',
	'submissionCard.answered': '共计答题',
	'submissionCard.totalTimeSpent': '总计用时',
	'submissionCard.correct': '答对题目',
	'submissionCard.incorrect': '答错题目',
	'submissionCard.score': '总分值',
	'submissionCard.details': '查看详情',
	'submission.retake': '再次测试',
	'question.title': '题目',
	'question.caseStudy.title': '套题-共{totalQuestion}小题 {subquestionId}/{totalQuestion}',
	'question.caseStudy.subquestion.title': '小题{subquestionId}: ',
	'question.questionCount': '总计题数',
	'question.timer': '倒计时',
	'question.back': '上一道',
	'question.next': '下一道',
	'question.option': '选项',
	'question.finish': '确认提交',
	'question.return': '返回',
	'question.review.correct': '答对',
	'question.review.incorrect': '答错',
	'question.review.correctOption': '正确选项: ',
	'question.review.analysis': '问题分析: ',
	'questionType.multipleChoice': '单选题',
	'questionType.checkboxes': '多选题',
	'questionType.caseStudy': '案例分析',
	'login.codePlaceholder': '请输入验证码',
	'login.requireMobile.errorMsg': '手机号不能为空',
	'login.requireCode.errorMsg': '验证码不能为空',
	'login.mobilePlaceholder': '请输入手机号',
	'login.errorMessage': '邮箱或密码错误',
	'login.mobile.errorMsg': '手机号格式错误',
	'login.mobile.notFoundMsg': '手机号不存在,请先注册',
	'login.sendCodeBtn.waitingText': '秒后重新获取',
	'login.sendCodeBtn.text': '获取验证码',
	'login.email.error.invalidInput': '邮箱格式不正确',
	'login.password.placeholder': '请输入密码',
	'login.password.label': '密码',
	'login.button': '登录',
	'login.signup': '立即注册',
	'login.placeholder': '请在此输入...',
	'login.forgotPassword': '忘记密码？',
	'login.rememberMe': '记住密码',
	'login.email': '邮箱登录',
	'login.phone': '手机号登录',
	'login.email.label': '邮箱',
	'login.email.required': '请输入邮箱',
	'login.password.required': '请输入密码',
	'login.title': '欢迎进入OA系统，海量题库任你选！',
	'login.subtitle': '请输入您的登录信息',
	'login.noAccount': '没有账户？',
	'login.loginWith': '其他登录方式',
	'login.success': '登录成功!',
	'login.failure': '登录失败，请重试。',
	'register.title': '欢迎注册OAP题库，海量题库任你选！',
	'register.email': '邮箱注册',
	'register.password.placeholder': '请输入密码',
	'register.confirmedPassword.placeholder': '请再输入一次密码',
	'register.password.error.emptyPassword': '请输入一个密码',
	'register.password.error.passwordFormat': '6-30字符，必须包含大写和小写字母，可输入特殊符号',
	'register.confirmPassword.error': '确认密码和新密码不一样',
	'register.password.error.passwordLength': '密码长度为6-30个字符',
	'register.password.error.passwordCase': '密码至少包含一个大写和一个小写字符',
	'register.email.error.emptyInput': '请输入一个邮箱',
	'register.email.error.invalidInput': '邮箱格式不正确',
	'register.email.placeholder': '请输入邮箱',
	'register.firstName.placeholder': '请输入名字',
	'register.lastName.placeholder': '请输入姓氏',
	'register.name.invalidInput': '姓名格式不正确',
	'register.name.error.required': '请输入姓名',
	'register.email.error.existing': '该邮箱已被使用',
	'register.emailVerification.placeholder': '输入邮箱验证码',
	'register.emailVerification.send': '发送验证码',
	'register.emailVerification.sent': '已发送: {seconds}s',
	'register.emailVerification.resend': '重新发送',
	'register.emailVerification.error.required': '请输入验证码',
	'register.emailVerification.error.notMatch': '验证码错误',
	'register.sendCodeBtn.text': '获取验证码',
	'register.sendCodeBtn.waitingText': '秒后重新获取',
	'register.success.text': '注册成功! 返回登录页登录',
	'register.success.btn': '去登录',
	'register.fail.text': '抱歉! 注册失败。原因: ',
	'register.fail.guide': '可刷新页面, 再次尝试!',
	'register.fail.btn': '刷新页面',
	'register.button': '确认注册',
	'register.terms': '同意条款：勾选即表示您同意 <a>用户协议与服务条款</a>',
	'register.terms.error': '请阅读OA系统服务条款, 并确认勾选',
	'register.haveAccount': '已有账号，',
	'register.login.link': '快速登录 →',
	'forgetPassword.send': '发送',
	'forgetPassword.resetPasswordLinkDescription1': '重置密码的连接已发送至你的注册邮箱',
	'forgetPassword.resetPasswordLinkDescription2': '请前往邮箱修改',
	'forgetPassword.title': '发送重置链接',
	'forgetPassword.email.placeholder': '请输入注册邮箱',
	'forgetPassword.email.error.unregistered': '* 未发现该邮箱信息, ',
	'forgetPassword.email.error.unregistered.link': '立即注册',
	'resetPassword.password.error.usedPassword': '密码不能和原来一样',
	'resetPassword.password.placeholder': '请输入新密码',
	'resetPassword.successMessage': '重置密码成功！前往登录',
	'resetPassword.signIn': '去登录',
	'resetPassword.title': '重置密码',
	'resetPassword.send': '确认重置'
};
