import request, { IResponseEntity } from '@/utils/request';
import { ICandidateBase, IEmailLoginRequest, ICheckEmailRequest, IVerifyEmailRequest, ISignupRequest, ISmsCodeRequest, IMobileLoginForm } from '@/interfaces/candidate';

export async function login(data: IEmailLoginRequest): Promise<IResponseEntity<ICandidateBase>> {
	return request.post('/candidate/login', { data });
}

export async function checkExistingEmail({ companyId, email }: ICheckEmailRequest): Promise<IResponseEntity<boolean>> {
	return request.get('/candidate/existing-email', {
		params: {
			companyId,
			email
		}
	});
}

export async function sendEmailCode(email: string): Promise<IResponseEntity<string>> {
	return request.get('/candidate/email-code', {
		params: {
			email
		}
	});
}

export async function verifyEmail(data: IVerifyEmailRequest): Promise<IResponseEntity<void>> {
	return request.post('/candidate/email-verification', { data });
}

export async function signup(data: ISignupRequest): Promise<IResponseEntity<ICandidateBase>> {
	return request.post('/candidate/signup', { data });
}

export async function sendMobileCode(params: ISmsCodeRequest): Promise<IResponseEntity<string>> {
	return request.get('/users/verificationSms', {
		method: 'GET',
		params
	});
}

export async function mobileLogin(data: IMobileLoginForm): Promise<IResponseEntity<ICandidateBase>> {
	return request.post('/candidate/mobile-login', { data });
}