// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.card-uMoG99 {\n  width: 100%;\n  min-width: 180px;\n  height: 130px;\n  padding: 15px 25px;\n}\n.left-vPpgHb {\n  flex: 1 1 150px;\n  overflow: hidden;\n  padding-right: 15px;\n  padding-top: 15px;\n}\n.dataItem-kna05F {\n  margin-bottom: 10px;\n  line-height: 22px;\n}\n.dataItem-kna05F > .dataItemLabel-iRjof4 {\n  font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://src/components/SubmissionResultOnlyCard/SubmissionCard.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAA7F;EACC,WAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;AAED;AACA;EACC,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AACD;AAEA;EACC,mBAAA;EACA,iBAAA;AAAD;AAFA;EAKE,gBAAA;AAAF","sourcesContent":["@import '~antd/es/style/themes/default.less';\n@import '~@/utils/utils.less';\n\n.card {\n\twidth: 100%;\n\tmin-width: 180px;\n\theight: 130px;\n\tpadding: 15px 25px;\n}\n\n.left {\n\tflex: 1 1 150px;\n\toverflow: hidden;\n\tpadding-right: 15px;\n\tpadding-top: 15px;\n}\n\n.dataItem {\n\tmargin-bottom: 10px;\n\tline-height: 22px;\n\n\t> .dataItemLabel {\n\t\tfont-weight: 600;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "card-uMoG99",
	"left": "left-vPpgHb",
	"dataItem": "dataItem-kna05F",
	"dataItemLabel": "dataItemLabel-iRjof4"
};
export default ___CSS_LOADER_EXPORT___;
