import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from 'react-router-dom';
import Invitation from '@/pages/Invitation';
import Assessment from '@/pages/Assessment';
import Submission from '@/pages/Submission';
import Login from '@/pages/User/Login';
import Register from '@/pages/User/Register';
import Outcome from '@/pages/User/Register/Outcome';
import { AssessmentHasExpired } from '@/pages/AssessmentHasExpired/AssessmentHasExpired';

const App: React.FC = () => {
	return (
		<Router>
			<Switch>
				<Route path="/login/:id" component={Login} />
				<Route path="/register/:id" component={Register} />
				<Route path="/register-outcome/:id/:outcome" component={Outcome} />
				<Route path="/dashboard/:id" component={Invitation} />
				<Route path="/invitation" component={Invitation} />
				<Route path="/urlInvitation" component={Invitation} />
				<Route path="/assessment" component={Assessment} />
				<Route path="/submission" component={Submission} />
				<Route path="/assessmentHasExpired" component={AssessmentHasExpired} />
			</Switch>
		</Router>
	);
};

export default App;
