import store from '@/store/store';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from './storage';
import { updateQuestionEndTime } from '@/store/question/actions';
import { ELocalStorageKey } from '@/constants/storage';

/**
 * Returns local storage key of current question end time with given invitation ID.
 * @param invitationId  invitation Id
 * @return local storage key of current question end time with given invitation ID.
 */
export const getQuestionEndTimeStorageKey = (invitationId: string): string => {
	return `${ELocalStorageKey.QUESTION_END_TIME_PREFIX}${invitationId}`;
};

/**
 * get current question end time from local storage with given invitation Id
 * @param invitationId invitation id
 * return current question end time
 */
export const getQuestionEndTime = (invitationId: string) : number | null => {
	return getLocalStorage<number>(getQuestionEndTimeStorageKey(invitationId));
};
/**
 * update current question end time to local storage and redux store
 * @param invitationId invitation id
 * @param questionEndTime End time for answering question
 */
export const updateQuestionEndTimeStorage = (
	invitationId: string,
	questionEndTime: number) : void => {
	if (!invitationId) {return;}
	const key = getQuestionEndTimeStorageKey(invitationId);
	setLocalStorage<number>(key, questionEndTime);
	store.dispatch(updateQuestionEndTime(questionEndTime));
};

export const removeQuestionEndTimeStorage = (invitationId: string) : void=> {
	removeLocalStorage(getQuestionEndTimeStorageKey(invitationId));
};

/**
 * recover current question end time from local storage to redux store
 * @param invitationId invitation ID
 */
export const resumeQuestionTimer = (invitationId: string) : Promise<boolean> => {
	return new Promise(((resolve, reject) => {
		const questionEndTime = getQuestionEndTime(invitationId);
		if (questionEndTime) {
			store.dispatch(updateQuestionEndTime(questionEndTime));
			resolve(true);
		}
		resolve(false);
	}));
};