// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container-HZFhNX {\n  text-align: center;\n}\n.container-HZFhNX .ant-progress-circle .ant-progress-text {\n  font-size: 28px;\n  color: #2c50ee;\n}\n", "",{"version":3,"sources":["webpack://src/components/CircularProgress/CircularProgress.less"],"names":[],"mappings":"AAEA;EACC,kBAAA;AADD;AAAA;EAMI,eAAA;EACA,cAAA;AAHJ","sourcesContent":["@import '~@/utils/utils.less';\n\n.container {\n\ttext-align: center;\n\n\t:global {\n\t\t.ant-progress-circle {\n\t\t\t.ant-progress-text {\n\t\t\t\tfont-size: 28px;\n\t\t\t\tcolor: @primary-color;\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container-HZFhNX"
};
export default ___CSS_LOADER_EXPORT___;
