import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { EMAIL_REGEX, NAME_REGEX } from '@/constants/regex';
import type { Rule } from 'antd/lib/form';
import type { IntlShape } from 'react-intl';
import classnames from 'classnames';
import { Form, Col, Row, Tabs } from 'antd';
import Button from '@/components/Button/Button';
import Password from '@/pages/User/components/Password';
import ProForm, { ProFormText, ProFormCheckbox, ProFormCaptcha } from '@ant-design/pro-form';
import { hasError, isFieldsFilled } from '@/utils/form';
import styles from './Register.less';
import userStyles from '@/pages/User/User.less';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { ECandidateActionTypes } from '@/constants/candidate';
import type { IConnectState } from '@/interfaces/redux';
import UserLayout from '@/layouts/UserLayout';
import type { ILoadingStatus } from '@/interfaces/loading';
import { getSessionStorage, removeSessionStorage } from '@/utils/storage';
import { ESessionStorageKey } from '@/constants/storage';
import { useHistory } from 'react-router';

const VERIFICATION_CODE_RESEND_SECONDS = 59;
const FIELD_NAMES = ['firstName', 'lastName', 'email', 'password', 'verificationCode', 'terms'];

interface IRegisterProps {
	loading?: ILoadingStatus;
}

const Register: React.FC<IRegisterProps> = () => {
	const [form] = ProForm.useForm();
	const dispatch = useDispatch();
	const intl = useIntl();
	const companyId = useParams()['id'];
	const [tabIndex, setTabIndex] = useState<string>('email');
	const [hasEmailCodeSent, setHasEmailCodeSent] = useState(false);
	const [sendButtonActive, setSendButtonActive] = useState(false);
	const history = useHistory();

	const nameFormatValidator = (intl: IntlShape) => (rule: Rule, value: string): Promise<void> => {
		if (!value) {
			return Promise.reject(intl.formatMessage({ id: 'register.name.error.required' }));
		}
		if (!NAME_REGEX.test(value)) {
			return Promise.reject(intl.formatMessage({ id: 'register.name.invalidInput' }));
		}
		return Promise.resolve();
	};

	const emailFormatValidator = (intl: IntlShape) => (rule: Rule, value: string): Promise<void> => {
		if (!value) {
			return Promise.reject(intl.formatMessage({ id: 'register.email.error.emptyInput' }));
		}
		if (!EMAIL_REGEX.test(value)) {
			return Promise.reject(intl.formatMessage({ id: 'register.email.error.invalidInput' }));
		}
		return Promise.resolve();
	};

	const existingEmailValidator = (
		intl: IntlShape,
		companyId: string,
		promiseRef?: React.MutableRefObject<Promise<void> | undefined>
	) => (rule: Rule, email: string): Promise<void> => {
		const validatorPromise = new Promise<void>(async (resolve, reject) => {
			await dispatch({
				type: ECandidateActionTypes.checkExistingEmail,
				payload: {
					email,
					companyId
				}
			});
			setTimeout(() => {
				const isEmailExisting: boolean | null = getSessionStorage(ESessionStorageKey.EXISTING);
				if (isEmailExisting) {
					reject(intl.formatMessage({ id: 'register.email.error.existing' }));
				} else {
					setSendButtonActive(true);
					resolve();
				}
				removeSessionStorage(ESessionStorageKey.EXISTING);
			}, 300);
		});
		return promiseRef
			? promiseRef.current = validatorPromise
			: validatorPromise;
	};

	const onSendCodeClick = async () => {
		setHasEmailCodeSent(true);
		await dispatch({
			type: ECandidateActionTypes.sendEmailCode,
			payload: form.getFieldValue('email')
		});
	};

	const emailCodeValidator = (
		intl: IntlShape,
		promiseRef?: React.MutableRefObject<Promise<void> | undefined>
	) => (rule: Rule, verificationCode: string): Promise<void> => {
		const { email, firstName, lastName, password } = form.getFieldsValue();
		const validatorPromise = new Promise<void>(async (resolve, reject) => {
			await dispatch({
				type: ECandidateActionTypes.verifyEmail,
				payload: { email, firstName, lastName, password, verificationCode }
			});
			setTimeout(() => {
				const isEmailVerified: boolean | null = getSessionStorage(ESessionStorageKey.VERIFIED);
				if (!isEmailVerified) {
					reject(intl.formatMessage({ id: 'register.emailVerification.error.notMatch' }));
					return;
				} else {
					resolve();
				}
				removeSessionStorage(ESessionStorageKey.VERIFIED);
			}, 300);
		});
		return promiseRef
			? promiseRef.current = validatorPromise
			: validatorPromise;
	};

	const onSubmitClick = async (): Promise<void> => {
		const { firstName, lastName, email, password } = form.getFieldsValue();
		const isRegistered = true;
		const source = 'email';
		await dispatch({
			type: ECandidateActionTypes.signup,
			payload: {
				firstName,
				lastName,
				password,
				email,
				source,
				isRegistered,
				companyId
			}
		});
		setTimeout(() => {
			const isSuccessful: boolean | null = getSessionStorage(ESessionStorageKey.REGISTERED);
			const outcome = isSuccessful? 'success' : 'fail';
			history.replace(`/register-outcome/${companyId}/${outcome}`);
			removeSessionStorage(ESessionStorageKey.REGISTERED);
		}, 300);
	};

	return (
		<>
			<UserLayout>
				<div className={`${userStyles.authForm} ${styles.registerForm}`} >
					<h2 className={userStyles.title}>
						<FormattedMessage id="register.title" />
					</h2>
					<ProForm
						form={form}
						submitter={false}
					>
						<Tabs
							defaultActiveKey={tabIndex}
							className={classnames('display-flex', 'justify-content-center', styles.tab)}
							onChange={(tab) => setTabIndex(tab)}
						>
							<Tabs.TabPane tab={<FormattedMessage id="register.email" />} key={'email'} >
								<Row className={styles.nameRow} gutter={12}>
									<Col className={styles.lastName} span={12} >
										<ProFormText
											name="lastName"
											placeholder={intl.formatMessage({ id: 'register.lastName.placeholder' })}
											fieldProps={{
												size: 'large',
												allowClear: false,
											}}
											validateTrigger={['onBlur']}
											validateFirst
											rules={[
												{ validator: nameFormatValidator(intl) },
											]}
										/>
									</Col>
									<Col className={styles.firstName} span={12} >
										<ProFormText
											name="firstName"
											placeholder={intl.formatMessage({ id: 'register.firstName.placeholder' })}
											fieldProps={{
												size: 'large',
												allowClear: false,
											}}
											validateTrigger={['onBlur']}
											validateFirst
											rules={[
												{ validator: nameFormatValidator(intl) },
											]}
										/>
									</Col>
								</Row>
								<ProFormText
									name="email"
									placeholder={intl.formatMessage({ id: 'register.email.placeholder' })}
									fieldProps={{
										size: 'large',
										allowClear: false,
									}}
									rules={[
										{ validator: emailFormatValidator(intl) },
										{ validator: existingEmailValidator(intl, companyId) },
									]}
									validateTrigger={['onBlur']}
									validateFirst
								/>
								<Password />
								<Form.Item noStyle shouldUpdate>
									{(form) => (
										<ProFormCaptcha
											name="verificationCode"
											fieldProps={{
												size: 'large'
											}}
											placeholder={intl.formatMessage({ id: 'register.emailVerification.placeholder' })}
											countDown={VERIFICATION_CODE_RESEND_SECONDS}
											captchaTextRender={(timing, count) => {
												if (timing) {
													return `${count}${intl.formatMessage({ id: 'register.sendCodeBtn.waitingText' })}`;
												}
												else {
													setSendButtonActive(true);
													return intl.formatMessage({ id: 'register.sendCodeBtn.text' });
												}
											}}
											onGetCaptcha={onSendCodeClick}
											rules={[
												{
													required: true,
													message: <FormattedMessage id="register.emailVerification.error.required" />,
												},
												{ validator: emailCodeValidator(intl) },
											]}
											captchaProps={{
												size: 'large',
												disabled: hasEmailCodeSent || !sendButtonActive
											}}
											validateTrigger={['onBlur']}
											validateFirst
										/>
									)}
								</Form.Item>
							</Tabs.TabPane>
						</Tabs>
						<div className={classnames('display-flex', 'justify-content-center', styles.terms)}>
							<ProFormCheckbox
								name="terms"
								rules={[{
									required: true,
									type: 'enum',
									enum: [true],
									message: <FormattedMessage id="register.terms.error" />
								}]}
							>
								<FormattedMessage
									id="register.terms"
									values={{
										a: (chunks: React.ReactElement) =>
											<Link to="/user/terms_and_conditions" target="_blank" rel="terms and conditions">{chunks}</Link>,
									}}
								/>
							</ProFormCheckbox>
						</div>
						<div className={classnames('display-flex', 'justify-content-center', userStyles.submit)} >
							<Form.Item noStyle shouldUpdate >
								{(form) => (
									<Button
										size="xlarge"
										shape="round"
										color="blue"
										reverseColor
										type="primary"
										htmlType="submit"
										disabled={form? (!isFieldsFilled(form, FIELD_NAMES) || hasError(form, FIELD_NAMES)) : false}
										onClick={onSubmitClick}
									>
										<FormattedMessage id="register.button" />
									</Button>
								)}
							</Form.Item>
						</div>
						<div className={classnames('display-flex', 'justify-content-center', 'font-size-lg', 'text-align-center')}>
							<FormattedMessage id="register.haveAccount" />
							<Link to={`/login/${companyId}`} className={styles.loginLink}>
								<FormattedMessage id="register.login.link" />
							</Link>
						</div>
					</ProForm>
				</div>
			</UserLayout>
		</>
	);
};

export default connect(({ loading }: IConnectState) => ({
	loading: loading[ECandidateActionTypes.checkExistingEmail]
	|| loading[ECandidateActionTypes.sendEmailCode]
	|| loading[ECandidateActionTypes.signup]
	|| loading[ECandidateActionTypes.verifyEmail],
}))(Register);