import type { IActionCreator } from '@/interfaces/redux';
import type { IEmailLoginRequest, ICandidateBase, ICheckEmailRequest, ISignupRequest, IVerifyEmailRequest, ISmsCodeRequest, IMobileLoginForm } from '@/interfaces/candidate';
import { ECandidateActionTypes } from '@/constants/candidate';

export const login: IActionCreator<IEmailLoginRequest> = (data) => ({
	type: ECandidateActionTypes.login,
	payload: data
});

export const saveCurrentCandidate: IActionCreator<ICandidateBase> = (candidate) => ({
	type: ECandidateActionTypes.saveCurrentCandidate,
	payload: candidate
});

export const checkExistingEmail: IActionCreator<ICheckEmailRequest> = (data) => ({
	type: ECandidateActionTypes.checkExistingEmail,
	payload: data
});

export const sendEmailCode: IActionCreator<string> = (email) => ({
	type: ECandidateActionTypes.checkExistingEmail,
	payload: email
});

export const verifyEmail: IActionCreator<IVerifyEmailRequest> = (data) => ({
	type: ECandidateActionTypes.checkExistingEmail,
	payload: data
});

export const signup: IActionCreator<ISignupRequest> = (data) => ({
	type: ECandidateActionTypes.signup,
	payload: data
});

export const sendMobileCode: IActionCreator<ISmsCodeRequest> = (data) => ({
	type: ECandidateActionTypes.sendMobileCode,
	payload: data
});

export const mobileLogin: IActionCreator<IMobileLoginForm> = (data) => ({
	type: ECandidateActionTypes.mobileLogin,
	payload: data
});