import { ModalProps, Modal, Button } from 'antd';
import styles from './TimeOutModal.less';
import { getLocalStorage } from '@/utils/storage';
import { FormattedMessage } from 'react-intl';
import { ELocalStorageKey } from '@/constants/storage';
import { IInvitationBase } from '@/interfaces/invitation';

interface ITimeOutModal extends ModalProps{
	visible: boolean;
	hideModal: () => void;
	submit: () => Promise<void>;
}

export const TimeOutModal: React.FC<ITimeOutModal> = (props) => {
	const { visible, hideModal, submit } = props;
	const companyEmail = getLocalStorage<IInvitationBase>(ELocalStorageKey.INVITATION)?.assessment.companyEmail;
	return (
		<Modal
			visible={visible}
			destroyOnClose={false}
			onCancel={hideModal}
			closable={false}
			afterClose={hideModal}
			footer={null}
			className={styles.timeOutWindow}
		>
			<h2>
				<FormattedMessage id="assessment.timeout.title" />
			</h2>
			<h3>
				<FormattedMessage id="assessment.timeout.company.contact" /> { companyEmail }
			</h3>
			<Button
				onClick={submit}
				className={styles.confirmButton}
			>
				<FormattedMessage id="assessment.timeout.confirm" />
			</Button>
			<h5>
				<FormattedMessage id="assessment.timeout.customer.contact" /> <a href="admin@OAP.com"> admin@OAP.com </a>
			</h5>
		</Modal>
	);
};
