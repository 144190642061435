import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { isEmpty, isNumber } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { EQuestionActionTypes } from '@/constants/question';
import { updateAssessmentRecord } from '@/utils/assessmentRecord';
import { IConnectState } from '@/interfaces/redux';
import classnames from 'classnames';
import Card from '@/components/Card';
import Button from '@/components/Button';
import { formatDuration } from '@/utils/date';
import type { IConvertedSubmission, ISubmissionCardDataItem } from '@/interfaces/submission';
import { EInvitationActionTypes, EInvitationStatus } from '@/constants/invitation';
import type { IAssessmentBase } from '@/interfaces/assessment';
import styles from './SubmissionCard.less';

interface ISubmissionCardProps {
	submission?: IConvertedSubmission;
	assessment?: IAssessmentBase;
	className?: string;
}

/**
 * The card that displays the result of a submission.
 */
const SubmissionResultOnlyCard: React.FC<ISubmissionCardProps> = ({
	submission,
	assessment,
	className,
}) => {
	const { answeredQuestions, totalSecondsSpent,
		score } = submission || {};

	const { showResult, showEachQuestionResult, questionCount } = assessment || {};
	const history = useHistory();
	const dispatch = useDispatch();
	const invitationId = useSelector(({ invitation }: IConnectState) => invitation.invitationId);

	const dataItems: ISubmissionCardDataItem[] = [
		{
			label: <FormattedMessage id="submissionCard.complete" />,
			value: `${answeredQuestions ?? '--'}/${questionCount ?? '--'}`,
			shouldShow: true,
		},
		{
			label: <FormattedMessage id="submissionCard.totalTimeSpent" />,
			value: totalSecondsSpent ? formatDuration(totalSecondsSpent) : '--',
			shouldShow: true,
		},
		{
			label: <FormattedMessage id="submissionCard.totalScore" />,
			value: score,
			shouldShow: showResult,
		},
	].filter(({ value, shouldShow }) => shouldShow && (isNumber(value) || !isEmpty(value)) );

	const reviewQuestionOnClick = () => {
		dispatch({
			type: EInvitationActionTypes.setStatus,
			payload: EInvitationStatus.REVIEWING
		});
		dispatch({
			type: EQuestionActionTypes.setCurrentQuestionIndex,
			payload: 0
		});
		if (invitationId) {
			updateAssessmentRecord(invitationId, 0);
		}
		history.push('/assessment');
	};

	const renderDataItem = ({
		label,
		value,
		className,
	}: ISubmissionCardDataItem, index: number): JSX.Element => {
		return (
			<div className={classnames('text-overflow-ellipsis', styles.dataItem)} key={index}>
				<span className={classnames(styles.dataItemLabel, className)}>
					{label}
				</span>
				<span className={className}>
					{value}
				</span>
			</div>
		);
	};

	return (
		<Card
			hasShadow
			className={classnames('display-flex', 'font-size-16', 'color-primary', styles.card, className)}
		>
			<div className={styles.left}>
				{dataItems.map(renderDataItem)}
			</div>
			<div>
				{showEachQuestionResult ? (
					<Button color="coral" reverseColor size="small" onClick={reviewQuestionOnClick}>
						<FormattedMessage id="submissionCard.review" />
					</Button>
				) : null}
			</div>
		</Card>
	);
};

export default SubmissionResultOnlyCard;
