import request, { IResponseEntity } from '@/utils/request';
import type { IInvitationBase } from '@/interfaces/invitation';
import type { IAssessmentBase } from '@/interfaces/assessment';

export async function getInvitationByToken(token: string): Promise<IResponseEntity<IInvitationBase>> {
	return request.get('/candidate/invitation', {
		params: {
			token,
		}
	});
}

export async function completeAssessment(invitationId: string): Promise<IResponseEntity<number>> {
	return request.get(`/candidate/invitation/${invitationId}/completion`);
}

export async function finishAssessment(invitationId: string): Promise<IResponseEntity<number>> {
	return request.get(`/candidate/invitation/${invitationId}/finish`);
}

export async function submitAssessment(invitationId: string): Promise<IResponseEntity<IInvitationBase>> {
	return request.put(`/candidate/invitation/${invitationId}/submission`);
}

export async function getAssessmentByToken(token: string): Promise<IResponseEntity<IAssessmentBase>> {
	return request.get(`/assessments/shared-assessment/${token}`);
}
