import React from 'react';
import SelectLang from '@/components/SelectLang';
import styles from './UserLayout.less';

export type UserLayoutProps = {
	className?: string;
}

const UserLayout: React.FC<UserLayoutProps> = ({
	children,
}) => {

	return (
		<div className={styles.userLayout}>
			<div className={styles.header}>
				<div className={styles.leftHeader}>
					<img src="/icons/sigma-whiteLogo.svg" alt="logo" />
				</div>
				<SelectLang />
			</div>
			<div className={styles.content}>
				{children}
			</div>
		</div>
	);
};

export default UserLayout;