import { combineReducers } from 'redux';
import intl from './intl/reducers';
import loading from './loading/reducers';
import invitation from './invitation/reducers';
import question from './question/reducers';
import candidate from './candidate/reducers';

export default combineReducers({
	intl,
	loading,
	invitation,
	question,
	candidate,
});