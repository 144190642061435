// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card-uX61pA {\n  border-radius: 10px;\n  background-color: white;\n}\n.shadow-neY3rn {\n  box-shadow: 0 7px 20px 0 rgba(231, 233, 243, 0.5);\n}\n", "",{"version":3,"sources":["webpack://src/components/Card/Card.less"],"names":[],"mappings":"AAAA;EACC,mBAAA;EACA,uBAAA;AACD;AAEA;EACC,iDAAA;AAAD","sourcesContent":[".card {\n\tborder-radius: 10px;\n\tbackground-color: white;\n}\n\n.shadow {\n\tbox-shadow: 0 7px 20px 0 rgba(231, 233, 243, 0.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "card-uX61pA",
	"shadow": "shadow-neY3rn"
};
export default ___CSS_LOADER_EXPORT___;
