import { ComponentState } from 'react';
import type { AnyAction, Reducer, ReducersMapObject } from 'redux';

/**
 * Creates a Redux reducer.
 * @param initialState
 * @param reducers A map from an action type to a reducer that handles this type.
 * @returns A Redux reducer.
 */
export const createReducer = (initialState: ComponentState, reducers: ReducersMapObject): Reducer =>
	(state = initialState, action: AnyAction) =>
		reducers[action.type]?.(state, action) || state;