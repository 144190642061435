import { createReducer } from '@/utils/redux';
import type { Reducer, ReducersMapObject } from 'redux';
import type { ICandidateBase, ICandidateStoreState } from '@/interfaces/candidate';
import { ECandidateActionTypes } from '@/constants/candidate';
import type { IAction } from '@/interfaces/redux';

const initialState: ICandidateStoreState = {};

const login: Reducer<ICandidateStoreState, IAction<ICandidateBase>> = (state, { payload }) => {
	return {
		...state,
		candidate: payload,
	};
};

const mobileLogin: Reducer<ICandidateStoreState, IAction<ICandidateBase>> = (state, { payload }) => {
	return {
		...state,
		candidate: payload,
	};
};

const saveCurrentCandidate: Reducer<ICandidateStoreState, IAction<ICandidateBase>> = (state, { payload }) => {
	return {
		...state,
		candidate: payload
	};
};

const reducers: ReducersMapObject = {
	[ECandidateActionTypes.login]: login,
	[ECandidateActionTypes.mobileLogin]: mobileLogin,
	[ECandidateActionTypes.saveCurrentCandidate]: saveCurrentCandidate,
};

export default createReducer(initialState, reducers);