import { call, put, select, takeEvery } from 'redux-saga/effects';
import { isEmpty, isFunction } from 'lodash';
import { startAssessment, getQuestion, saveSelectedOptions, getQuestionReview } from '@/services/question';
import { insertQuestion, updateQuestion, clearAnswerRecords, setCurrentQuestionIndex } from './actions';
import { setLoading } from '@/store/loading/actions';
import { INITIAL_QUESTION_INDEX } from './reducers';
import type { ForkEffect } from 'redux-saga/effects';
import type { IResponseEntity } from '@/utils/request';
import type { IAction, IConnectState } from '@/interfaces/redux';
import type { IGetQuestionRequest, IQuestionBase, IQuestionReviewBase, ISaveSelectedOptionsRequest } from '@/interfaces/question';
import type { SagaIterator } from 'redux-saga';
import { EQuestionActionTypes } from '@/constants/question';
import { setQuestionCount } from '../invitation/actions';
import { getLocalStorage, setLocalStorage } from '@/utils/storage';
import { ESessionStorageKey } from '@/constants/storage';
import { IInvitationStoreState } from '@/interfaces/invitation';

function* startAssessmentEffect({ payload, callback }: IAction<string>) {
	if (!payload) return;
	yield put(clearAnswerRecords());
	yield put(setCurrentQuestionIndex(INITIAL_QUESTION_INDEX));
	yield put(setLoading(EQuestionActionTypes.startAssessment, true));
	const responseEntity: IResponseEntity<IQuestionBase> = yield call(startAssessment, payload);
	const { error, data } = responseEntity;
	yield put(insertQuestion(data));
	const invitation = getLocalStorage<IInvitationStoreState>(ESessionStorageKey.INVITATION);
	yield put(setQuestionCount(data?.questionCount));
	if (invitation) {
		invitation.questionCount = data?.questionCount;
		setLocalStorage(ESessionStorageKey.INVITATION, invitation);
	}
	yield put(setLoading(EQuestionActionTypes.startAssessment, false, error));
	if (isFunction(callback)) callback();
}

function* fetchQuestion({ payload }: IAction<IGetQuestionRequest>) {
	if (!payload) return;
	yield put(setLoading(EQuestionActionTypes.fetchQuestion, true));
	const responseEntity: IResponseEntity<IQuestionBase> = yield call(getQuestion, payload);
	const { error, data } = responseEntity;
	if (data && !isEmpty(data)) {
		yield put(updateQuestion({ [payload.questionIndex]: data }));
	}

	yield put(setLoading(EQuestionActionTypes.fetchQuestion, false, error));
}

function* saveSelectedOptionsEffect({ payload }: IAction<ISaveSelectedOptionsRequest>) {
	if (!payload) return;
	yield put(setLoading(EQuestionActionTypes.fetchQuestion, true));
	const responseEntity: IResponseEntity = yield call(saveSelectedOptions, payload);
	const { error } = responseEntity;
	yield put(setLoading(EQuestionActionTypes.fetchQuestion, false, error));
	yield put({ type: EQuestionActionTypes.saveSelectedOptionsEnd });
}

function* saveCurrentSelectedOptions(): SagaIterator<void> {
	const saveSelectedOptionsRequest: ISaveSelectedOptionsRequest = yield select(({ invitation, question }: IConnectState) => {
		const { invitationId } = invitation;
		const { currentQuestionIndex, answerRecords } = question;
		const selectedOptions = answerRecords?.[currentQuestionIndex];
		return { invitationId, questionIndex: currentQuestionIndex, selectedOptions };
	});
	yield put({
		type: EQuestionActionTypes.saveSelectedOptions,
		payload: saveSelectedOptionsRequest,
	});
}

function* fetchQuestionReview({ payload }: IAction<IGetQuestionRequest>) {
	if (!payload) return;
	yield put(setLoading(EQuestionActionTypes.fetchQuestionReview, true));
	const responseEntity: IResponseEntity<IQuestionReviewBase> = yield call(getQuestionReview, payload);
	const { error, data } = responseEntity;
	if (data && !isEmpty(data)) {
		const question: IQuestionBase = yield select(({ question }: IConnectState) => question?.questions?.[payload.questionIndex]);
		yield put(updateQuestion({ [payload.questionIndex]: {
			...question,
			questionReview: data
		} }));
	}
	yield put(setLoading(EQuestionActionTypes.fetchQuestionReview, false, error));
}

export default function* watchQuestion(): Generator<ForkEffect<never>, void, unknown> {
	yield takeEvery(EQuestionActionTypes.startAssessment, startAssessmentEffect);
	yield takeEvery(EQuestionActionTypes.fetchQuestion, fetchQuestion);
	yield takeEvery(EQuestionActionTypes.saveSelectedOptions, saveSelectedOptionsEffect);
	yield takeEvery(EQuestionActionTypes.saveCurrentSelectedOptions, saveCurrentSelectedOptions);
	yield takeEvery(EQuestionActionTypes.fetchQuestionReview, fetchQuestionReview);
}
