// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.countDownTimer-fFgoLd {\n  display: flex;\n  border-radius: 8px;\n  padding: 8px 11px;\n  color: white;\n  background-color: #fe8986ff;\n}\n.countDownTimer-fFgoLd .title-6iI3cP {\n  flex: 0 1 35px;\n  font-size: 12px;\n  padding-right: 10px;\n  border-right: 1px solid rgba(255, 255, 255, 0.2);\n  margin-right: 10px;\n}\n.countDownTimer-fFgoLd .countDown-fF9bq9 {\n  flex: 0 1 0;\n  font-size: 24px;\n  font-weight: 600;\n}\n.countDownTimer-fFgoLd .countDown-fF9bq9 .seconds-iC5Laj {\n  font-size: 18px;\n  padding-left: 5px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/Assessment/Components/CountDownTimer/CountDownTimer.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAA7F;EACC,aAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,2BAAA;AAED;AAPA;EAQE,cAAA;EACA,eAAA;EACA,mBAAA;EACA,gDAAA;EACA,kBAAA;AAEF;AAdA;EAgBE,WAAA;EACA,eAAA;EACA,gBAAA;AACF;AAnBA;EAqBG,eAAA;EACA,iBAAA;AACH","sourcesContent":["@import '~antd/es/style/themes/default.less';\n@import '~@/utils/utils.less';\n\n.countDownTimer {\n\tdisplay: flex;\n\tborder-radius: 8px;\n\tpadding: 8px 11px;\n\tcolor: white;\n\tbackground-color: #fe8986ff;\n\n\t.title {\n\t\tflex: 0 1 35px;\n\t\tfont-size: 12px;\n\t\tpadding-right: 10px;\n\t\tborder-right: 1px solid rgba(255, 255, 255, 0.2);\n\t\tmargin-right: 10px;\n\t}\n\n\t.countDown {\n\t\tflex: 0 1 0;\n\t\tfont-size: 24px;\n\t\tfont-weight: 600;\n\n\t\t.seconds {\n\t\t\tfont-size: 18px;\n\t\t\tpadding-left: 5px;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"countDownTimer": "countDownTimer-fFgoLd",
	"title": "title-6iI3cP",
	"countDown": "countDown-fF9bq9",
	"seconds": "seconds-iC5Laj"
};
export default ___CSS_LOADER_EXPORT___;
