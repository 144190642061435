import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';
import BasicLayout from '@/layouts/BasicLayout';
import AssessmentCover from './components/AssessmentCover';
import {
	fetchInvitationByToken,
	fetchAssessmentByToken,
	setInvitation
} from '@/store/invitation/actions';
import { startAssessment } from '@/store/question/actions';
import { resumeAssessmentRecord, removeAssessmentRecord } from '@/utils/assessmentRecord';
import { EInvitationActionTypes, EInvitationStatus } from '@/constants/invitation';
import type { IActionCreator, IConnectState } from '@/interfaces/redux';
import type { ILoadingStatus } from '@/interfaces/loading';
import type { IAssessmentBase } from '@/interfaces/assessment';
import styles from './Invitation.less';
import { removeQuestionEndTimeStorage } from '@/utils/questionEndTimeStorage';
import { disableBackButton } from '@/utils/utils';

interface InvitationProps {
	invitationId?: string;
	invitationStatus?: string;
	attemptsLeft?: number;
	assessment?: IAssessmentBase;
	loading?: ILoadingStatus;
	fetchAssessmentByToken?: IActionCreator<string>;
	fetchInvitationByToken: IActionCreator<string>;
	startAssessment: IActionCreator<string>;
}

const Invitation: React.FC<InvitationProps> = (props) => {
	const { invitationId, invitationStatus, attemptsLeft, assessment, fetchInvitationByToken, startAssessment, fetchAssessmentByToken, loading } = props;
	const history = useHistory();
	const isResumable: boolean = !assessment?.duration && (invitationStatus === EInvitationStatus.STARTED);
	const isCompleted: boolean = !isResumable && attemptsLeft === 0;
	const location = useLocation();
	const token = new URLSearchParams(location.search).get('token');
	const isUrlInvitation: boolean = location.pathname==='/urlInvitation'? true : false;

	const dispatch = useDispatch();

	useEffect(() => {
		disableBackButton();
	}, []);

	useEffect(() => {
		if (!!invitationId && isResumable) {
			resumeAssessmentRecord(invitationId);
		}
	}, [invitationId, isResumable]);

	const onStartAssessmentClick = (): void => {
		if (!invitationId) return;
		if (!isResumable) {
			removeAssessmentRecord(invitationId);
			removeQuestionEndTimeStorage(invitationId);
			dispatch({
				type: EInvitationActionTypes.setStatus,
				payload: EInvitationStatus.SUBMITTED
			});
			startAssessment(invitationId, () => {
				history.push('/assessment');
			});
		} else {
			history.push('/assessment');
		}
	};

	useEffect(() => {
		if (!!token && !isUrlInvitation) {
			fetchInvitationByToken(token);
		}
	}, [location, fetchInvitationByToken]);

	useEffect(() => {
		const getAssessmentByToken = async (token: string | undefined) =>{
			await dispatch({
				type: EInvitationActionTypes.fetchAssessmentByToken,
				payload: token
			});
		};
		if (isUrlInvitation && token) {
			getAssessmentByToken(token);
		}
	}, [location, fetchAssessmentByToken]);

	const renderValidInvitation = (): JSX.Element | null => {
		return (
			assessment? (
				<AssessmentCover
					assessment={assessment}
					attemptsLeft={attemptsLeft}
					isResumable={isUrlInvitation? false : isResumable}
					onStartAssessmentClick={onStartAssessmentClick}
					isUrlShared={isUrlInvitation}
				/>
			) : (loading?.error? (
				<h2>
					<FormattedMessage id="invitation.invalid" />
				</h2>
			): null)
		);
	};

	return (
		<BasicLayout>
			<Row className={styles.container}>
				<Col lg={12} className={styles.welcomeFirstCol}>
					<img className={styles.welcome_img} src="/images/welcome_left.svg" alt="welcome" />
				</Col>
				<Col lg={12} className={styles.welcomeSecondCol}>
					{isCompleted && !loading?.isLoading? (
						<h2>
							<FormattedMessage id="invitation.completed" />
						</h2>
					) : (renderValidInvitation())}
				</Col>
			</Row>
		</BasicLayout>
	);
};

export default connect(({ invitation, loading }: IConnectState ) => ({
	invitationId: invitation.invitationId,
	invitationStatus: invitation.status,
	attemptsLeft: invitation.attemptsLeft,
	assessment: invitation.assessment,
	loading: loading[EInvitationActionTypes.fetchInvitationByToken] || loading[EInvitationActionTypes.fetchAssessmentByToken],
}), {
	fetchInvitationByToken,
	fetchAssessmentByToken,
	startAssessment,
	setInvitation,
})(Invitation);
