import { call, put, take, takeEvery } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { getInvitationByToken, completeAssessment, submitAssessment, getAssessmentByToken } from '@/services/invitation';
import { setInvitation, setTotalTimeSpent, setInvitationStatus, setAssessment } from './actions';
import { saveCurrentSelectedOptions } from '@/store/question/actions';
import { setLoading } from '@/store/loading/actions';
import { setLocalStorage, setSessionStorage } from '@/utils/storage';
import type { ForkEffect } from 'redux-saga/effects';
import type { IResponseEntity } from '@/utils/request';
import type { IAction } from '@/interfaces/redux';
import type { IInvitationBase } from '@/interfaces/invitation';
import { EInvitationActionTypes, EInvitationStatus } from '@/constants/invitation';
import { ESessionStorageKey } from '@/constants/storage';
import { EQuestionActionTypes } from '@/constants/question';
import type { IAssessmentBase } from '@/interfaces/assessment';

function* fetchInvitationByToken({ payload }: IAction<string>) {
	if (!payload) return;
	yield put(setLoading(EInvitationActionTypes.fetchInvitationByToken, true));
	const responseEntity: IResponseEntity<IInvitationBase> = yield call(getInvitationByToken, payload);
	const { error, data } = responseEntity;
	if (isEmpty(data) || !data) return;
	yield put(setInvitation(data));
	setLocalStorage(ESessionStorageKey.INVITATION, data);
	setSessionStorage(ESessionStorageKey.INVITATION, data);
	yield put(setLoading(EInvitationActionTypes.fetchInvitationByToken, false, error));
}

function* completeAssessmentSaga({ payload }: IAction<string>) {
	if (!payload) return;
	yield put(setLoading(EInvitationActionTypes.completeAssessment, true));
	const responseEntity: IResponseEntity<number> = yield call(completeAssessment, payload);
	const { error, data } = responseEntity;
	yield put(setTotalTimeSpent(data));
	yield put(setInvitationStatus(EInvitationStatus.COMPLETED));
	yield put(setLoading(EInvitationActionTypes.completeAssessment, false, error));
}

function* submitAssessmentSaga({ payload, callback }: IAction<string>) {
	if (!payload) return;
	yield put(setLoading(EInvitationActionTypes.submitAssessment, true));
	yield put(saveCurrentSelectedOptions());
	yield take(EQuestionActionTypes.saveSelectedOptionsEnd);
	yield call(completeAssessment, payload);
	const responseEntity: IResponseEntity<IInvitationBase> = yield call(submitAssessment, payload);
	const { error, data } = responseEntity;
	if (data) {
		setLocalStorage(ESessionStorageKey.INVITATION, data);
	}
	yield put(setInvitation(data));
	yield put(setLoading(EInvitationActionTypes.submitAssessment, false, error));
	callback?.();
}

function* fetchAssessmentByToken({ payload }: IAction<string>) {
	if (!payload) return;
	yield put(setLoading(EInvitationActionTypes.fetchAssessmentByToken, true));
	const responseEntity: IResponseEntity<IAssessmentBase> = yield call(getAssessmentByToken, payload);
	const { error, data } = responseEntity;
	if (isEmpty(data) || !data) return;
	yield put(setAssessment(data));
	setSessionStorage(ESessionStorageKey.ASSESSMENT, data);
	yield put(setLoading(EInvitationActionTypes.fetchAssessmentByToken, false, error));
}

export default function* watchInvitation(): Generator<ForkEffect<never>, void, unknown> {
	yield takeEvery(EInvitationActionTypes.fetchInvitationByToken, fetchInvitationByToken);
	yield takeEvery(EInvitationActionTypes.fetchAssessmentByToken, fetchAssessmentByToken);
	yield takeEvery(EInvitationActionTypes.completeAssessment, completeAssessmentSaga);
	yield takeEvery(EInvitationActionTypes.submitAssessment, submitAssessmentSaga);
}
