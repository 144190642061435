export enum ELocalStorageKey {
	ASSESSMENT_RECORD_PREFIX = 'assessmentRecord_',
	QUESTION_END_TIME_PREFIX = 'questionEndTime_',
	ASSESSMENT_PREFIX = 'assessment_',
	CANDIDATE = 'candidate',
	TOKEN = 'token',
	INVITATION = 'invitation',
}

export enum ESessionStorageKey {
	INVITATION = 'invitation',
	ASSESSMENT = 'assessment',
	CANDIDATE = 'candidate',
	EXISTING = 'existing',
	VERIFIED = 'verified',
	REGISTERED = 'registered'
}