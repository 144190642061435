import React from 'react';
import { FormattedMessage } from 'react-intl';

interface IQuestionAnalysisProps {
	questionAnalysis?: string;
}

const QuestionAnalysis: React.FC<IQuestionAnalysisProps> = ({
	questionAnalysis
}) => {
	return (
		<div>
			<FormattedMessage id="question.review.analysis" />
			<div>
				{questionAnalysis}
			</div>
		</div>
	);
};

export default QuestionAnalysis;
