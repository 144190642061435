export enum EInvitationStatus {
	NO_REPLY = 'NO_REPLY', // // invitation has been sent but candidate has not started assessment yet
	STARTED = 'STARTED', // assessment started but not completed yet
	COMPLETED = 'COMPLETED', // assessment completed but not submitted yet
	REVIEWING = 'REVIEWING', // assessment is under reviewing
	SUBMITTED = 'SUBMITTED', // assessment submitted
}

export enum EInvitationActionTypes {
	setInvitation = 'invitation/setInvitation',
	fetchInvitationByToken = 'invitation/fetchInvitationByToken',
	setTotalTimeSpent = 'invitation/setTotalTimeSpent',
	completeAssessment = 'invitation/completeAssessment',
	finishAssessment = 'invitation/finishAssesssment',
	submitAssessment = 'invitation/submitAssessment',
	setStatus = 'invitation/setStatus',
	setQuestionCount = 'invitation/setQuestionCount',
	fetchAssessmentByToken = 'invitation/fetchAssessmentByToken',
	setAssessment = 'invitation/setAssessment',
	setAttemptsLeft = 'invitation/setAttemptsLeft',
}
