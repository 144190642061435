import { isEqual, isObject, isEmpty } from 'lodash';

/**
 * Compares the equality of two number arrays, ingoring order
 * @example containSameElements([1, 2], [2, 1]); // true
 * @param options1 selected options one
 * @param options2 selected options two
 * @returns true if two selected options contains same elements
 */
export const containSameElements = (array1?: number[], array2?: number[]): boolean => {
	return isEqual(new Set(array1), new Set(array2));
};

/**
 * Get the number of completed questions
 * @param answerRecords answer records
 * @returns number of questions that has a non-empty answer record
 */
export const getCompletedQuestionCount = (answerRecords?: Record<number, number[]>): number => {
	if (!isObject(answerRecords)) return 0;
	return Object.values(answerRecords)
		.filter(answerRecord => !isEmpty(answerRecord))
		.length;
};

/**
 *  disable back button of browser
 */
export const disableBackButton = (): void => {
	window.history.pushState(null, document.title, document.URL);
	window.addEventListener('popstate', function () {
		window.history.pushState(null, document.title, document.URL);
	});
};
