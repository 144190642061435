import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import BasicLayout from '@/layouts/BasicLayout';
import SubmissionCard from '@/components/SubmissionCard';
import Button from '@/components/Button';
import type { IActionCreator, IConnectState } from '@/interfaces/redux';
import type { IAssessmentBase } from '@/interfaces/assessment';
import type { IConvertedSubmission } from '@/interfaces/submission';
import styles from './Submission.less';
import SubmissionResultOnlyCard from '@/components/SubmissionResultOnlyCard';
import { EInvitationActionTypes, EInvitationStatus } from '@/constants/invitation';
import { finishAssessment } from '@/services/invitation';
import { IInvitationBase } from '@/interfaces/invitation';
import { setInvitation } from '@/store/invitation/actions';
import { getLocalStorage, setLocalStorage } from '@/utils/storage';
import { disableBackButton } from '@/utils/utils';
import { getCurrentInvitation } from '@/utils/invitation';
import { ESessionStorageKey } from '@/constants/storage';

interface ISubmissionProps {
	invitationId?: string;
	assessment?: IAssessmentBase;
	currentAttempt?: IConvertedSubmission;
	canRetake?: boolean;
	invitationStatus?: string;
	setInvitation: IActionCreator<IInvitationBase>;
	completeAssessment: IActionCreator<string>;
}

const Submission: React.FC<ISubmissionProps> = ({
	invitationId,
	assessment,
	currentAttempt,
	canRetake,
	setInvitation,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const resumeInvitation = useCallback(function (): void {
		const resumedInvitation = getLocalStorage<IInvitationBase>('invitation');
		if (!!resumedInvitation) {
			setInvitation(resumedInvitation);
		}
	}, [setInvitation]);

	useEffect(function () {
		if (!invitationId) {
			resumeInvitation();
			return;
		}
	}, [invitationId, resumeInvitation]);

	useEffect(() => {
		disableBackButton();
	}, []);

	if (!assessment) return <BasicLayout />;
	const { name } = assessment;
	const invitation = getCurrentInvitation();
	const endDate = invitation?.assessment.endDate? new Date(invitation?.assessment.endDate) : null;

	const completeOnclick = () => {
		if (!invitationId) return;
		finishAssessment(invitationId);

		dispatch({
			type: EInvitationActionTypes.setStatus,
			payload: EInvitationStatus.COMPLETED
		});

		dispatch({
			type: EInvitationActionTypes.setAttemptsLeft,
			payload: 0
		});

		history.push('/invitation');
	};

	const AssessmentHasExpired = () => {
		history.push('/assessmentHasExpired');
	};

	const reTake = () => {
		const invitation = getCurrentInvitation();
		if (invitation) {
			invitation.status = EInvitationStatus.SUBMITTED;
			setLocalStorage(ESessionStorageKey.INVITATION, invitation);
		}
		history.push('/invitation');
	};

	return (
		<BasicLayout assessmentName={name}>
			<div
				className={canRetake? classnames('display-flex', 'flex-direction-column', 'align-items-center', styles.submission):classnames('display-flex', 'flex-direction-column', 'align-items-center', styles.defaultSubmission)}
			>
				<div className={styles.icon}>
					<img src="/icons/result.svg" alt="smile" />
				</div>
				{assessment.showResult && assessment.showEachQuestionResult?(
					<>
						<div className={classnames('font-size-20', styles.title)}>
							<FormattedMessage id="submission.title" />
						</div>
						<div className={styles.cardWrapper}>
							<SubmissionCard
								submission={currentAttempt}
								assessment={assessment}
							/>
						</div>
					</>
				) :
						(
							<>
								<div className={classnames('font-size-20', styles.onlyResultTitle)}>
									<FormattedMessage id="submission.assignmentTitle" />
								</div>
								<div className={styles.cardResultAndScore}>
									<SubmissionResultOnlyCard
										submission={currentAttempt}
										assessment={assessment}
									/>
								</div>
							</>
						)
				}
				<div className={styles.buttons}>
					{canRetake ? (
						<>
							<Button color="white" reverseColor size="small" onClick={(endDate!==null && (new Date() >= endDate))? AssessmentHasExpired:reTake}>
								<FormattedMessage id="submission.retake" />
							</Button>
							<Button onClick={completeOnclick} reverseColor>
								<FormattedMessage id="common.complete" />
							</Button>
						</>
					) : null}
				</div>
			</div>
		</BasicLayout>
	);
};

export default connect(({ invitation }: IConnectState ) => ({
	invitationId: invitation.invitationId,
	canRetake: invitation.canRetake,
	assessment: invitation.assessment,
	currentAttempt: invitation.currentAttempt,
	invitationStatus: invitation.status,
	AttemptsLeft: invitation.attemptsLeft,
}), {
	finishAssessment,
	setInvitation
})(Submission);
