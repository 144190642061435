import { ISetLoadingAction } from '@/interfaces/loading';
import { ELoadingActionTypes } from '@/constants/loading';

export const setLoading = (
	path: string,
	value: boolean,
	error?: string
): ISetLoadingAction => ({
	type: ELoadingActionTypes.setLoading,
	payload: { path, value, error },
});
