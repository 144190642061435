import { Modal, ModalFuncProps } from 'antd';
import type { ModalFunc } from 'antd/lib/modal/confirm';
import styles from './ConfirmModal.less';

const ConfirmModal: ModalFunc = (
	props: ModalFuncProps
) => {
	return (
		Modal.confirm({
			className: styles.confirmModal,
			icon: null,
			okType: 'default',
			...props,
		})
	);
};

export default ConfirmModal;