import React from 'react';
import { Button as AntdButton } from 'antd';
import classnames from 'classnames';
import type { ButtonProps } from 'antd';
import styles from './Button.less';

interface IButtonProps extends Omit<ButtonProps, 'size'> {
	size?: 'normal' | 'default' | 'small' | 'large' | 'xlarge';
	color?: 'transparent' | 'blue' | 'skyblue' | 'orange' | 'coral' | 'white';
	reverseColor?: boolean;
}

const Button: React.FC<IButtonProps> = ({
	size = 'normal',
	color = 'blue',
	reverseColor = false,
	className,
	children,
	...restProps
}) => {
	const colorClass = reverseColor ? styles[`${color}--reverse`] : styles[color];
	return (
		<AntdButton
			shape="round"
			className={classnames(
				styles.button,
				colorClass,
				styles[size],
				className
			)}
			{...restProps}
		>
			{children}
		</AntdButton>
	);
};

export default Button;
