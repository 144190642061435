export enum EQuestionActionTypes {
	insertQuestion = 'question/insertQuestion',
	updateQuestion = 'question/updateQuestion',
	setCurrentQuestionIndex = 'question/setCurrentQuestionIndex',
	startAssessment = 'question/startAssessment',
	fetchQuestion = 'question/fetchQuestion',
	fetchQuestionReview = 'question/fetchQuestionReview',
	updateAnswerRecords = 'question/updateAnswerRecords',
	clearAnswerRecords = 'question/clearAnswerRecords',
	saveSelectedOptions = 'question/saveSelectedOptions',
	saveSelectedOptionsEnd = '@@END/question/saveSelectedOptions',
	saveCurrentSelectedOptions = 'question/saveCurrentSelectedOptions',
	completeQuestion = 'question/completeQuestion',
	updateCompletedQuestions = 'question/updateCompletedQuestions',
	updateQuestionEndTime = 'question/updateQuestionEndTime',
}

export enum EQuestionType {
	MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
	CHECKBOXES = 'CHECKBOXES',
	CASE_STUDY = 'CASE_STUDY',
}

export enum EQuestionTypeText {
	MULTIPLE_CHOICE = 'questionType.multipleChoice',
	CHECKBOXES = 'questionType.checkboxes',
	CASE_STUDY = 'questionType.caseStudy',
}