import { IGetQuestionRequest, IQuestionBase, IQuestionReviewBase, ISaveSelectedOptionsRequest } from '@/interfaces/question';
import request, { IResponseEntity } from '@/utils/request';

export async function startAssessment(invitationId: string): Promise<IResponseEntity<IQuestionBase>> {
	return request.get(`/candidate/invitation/${invitationId}`);
}

export async function getQuestion({
	invitationId,
	questionIndex,
}: IGetQuestionRequest): Promise<IResponseEntity<IQuestionBase>> {
	return request.get(`/candidate/invitation/${invitationId}/question/${questionIndex}`);
}

export async function saveSelectedOptions({
	invitationId,
	questionIndex,
	selectedOptions,
}: ISaveSelectedOptionsRequest): Promise<IResponseEntity<IQuestionBase>> {
	return request.put(`/candidate/invitation/${invitationId}/question/${questionIndex}`, {
		params: {
			selectedOptions,
		}
	});
}

export async function getQuestionReview({
	invitationId,
	questionIndex,
}: IGetQuestionRequest): Promise<IResponseEntity<IQuestionReviewBase>> {
	return request.get(`/candidate/invitation/${invitationId}/question/review/${questionIndex}`);
}