import { createReducer } from '@/utils/redux';
import { isEmpty } from 'lodash';
import { convertInvitationResponse } from '@/utils/invitation';
import type { Reducer, ReducersMapObject } from 'redux';
import type { IInvitationBase, IInvitationStoreState } from '@/interfaces/invitation';
import { EInvitationActionTypes, EInvitationStatus } from '@/constants/invitation';
import type { IAction } from '@/interfaces/redux';
import { IAssessmentBase } from '@/interfaces/assessment';

const initialState: IInvitationStoreState = {};

const setInvitation: Reducer<IInvitationStoreState, IAction<IInvitationBase>> = (state = {}, { payload }) => {
	if (!payload || isEmpty(payload)) return state;
	return {
		...state,
		...convertInvitationResponse(payload),
	};
};

const setTotalTimeSpent: Reducer<IInvitationStoreState, IAction<number>> = (state, { payload }) => {
	return {
		...state,
		currentAttempt: {
			...state?.currentAttempt,
			totalTimeSpent: payload,
		},
	};
};

const setStatus: Reducer<IInvitationStoreState, IAction<EInvitationStatus>> = (state, { payload }) => {
	return {
		...state,
		status: payload,
	};
};

const setQuestionCount: Reducer<IInvitationStoreState, IAction<number>> = (state, { payload }) => {
	return {
		...state,
		questionCount: payload
	};
};

const setAssessment: Reducer<IInvitationStoreState, IAction<IAssessmentBase>> = (state, { payload }) => {
	return {
		...state,
		assessment: payload
	};
};

const setAttemptsLeft: Reducer<IInvitationStoreState, IAction<number>> = (state, { payload }) => {
	return {
		...state,
		attemptsLeft: payload
	};
};

const reducers: ReducersMapObject = {
	[EInvitationActionTypes.setInvitation]: setInvitation,
	[EInvitationActionTypes.setTotalTimeSpent]: setTotalTimeSpent,
	[EInvitationActionTypes.setStatus]: setStatus,
	[EInvitationActionTypes.setQuestionCount]: setQuestionCount,
	[EInvitationActionTypes.setAssessment]: setAssessment,
	[EInvitationActionTypes.setAttemptsLeft]: setAttemptsLeft,
};

export default createReducer(initialState, reducers);