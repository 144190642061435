import React from 'react';
import classnames from 'classnames';
import Card from '@/components/Card';
import styles from './InfoCard.less';

interface IInfoCardProps {
	icon: React.ReactElement;
	label: React.ReactNode;
	value?: React.ReactNode;
}

const InfoCard: React.FC<IInfoCardProps> = ({
	icon,
	label,
	value,
}) => {
	return (
		<Card className={styles.infoCard}>
			<div className={styles.icon}>
				{icon}
			</div>
			<div className={classnames('text-overflow-ellipsis', styles.text)}>
				{label}{': '}{value}
			</div>
		</Card>
	);
};

export default InfoCard;