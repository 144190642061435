import store from '@/store/store';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from './storage';
import { setCurrentQuestionIndex, updateAnswerRecords, updateCompletedQuestions } from '@/store/question/actions';
import { ELocalStorageKey } from '@/constants/storage';
import type { IAssessmentRecord } from '@/interfaces/assessment';

/*
 * Utils for assessment record in local storage.
 * The purpose of assessment record is to store and resume assessment progress.
 * The key for assessment record is in the format of 'assessmentRecords_[invitationId]' for different invitations.
 */

/**
 * Returns local storage key of assessment record with given invitation ID.
 * @param invitationId invitation ID
 * @returns local storage key of assessment record
 */
export const getLocalStorageKey = (invitationId: string): string => {
	return `${ELocalStorageKey.ASSESSMENT_RECORD_PREFIX}${invitationId}`;
};

/**
 * Gets assessment record with given invitation ID.
 * @param invitationId invitation ID
 * @returns assessment record
 */
export const getAssessmentRecord = (invitationId: string): IAssessmentRecord | null => {
	return getLocalStorage<IAssessmentRecord>(getLocalStorageKey(invitationId));
};

/**
 * Updates assessment record with given invitation ID.
 * @param invitationId invitation ID
 * @param currentQuestionIndex current question index
 * @param questionId completed question's id
 * @param answerRecord a key-value pair representing candidate's answer to a question
 */
export const updateAssessmentRecord = (
	invitationId: string,
	currentQuestionIndex: number,
	questionId?: string | null,
	answerRecord?: Record<number, number[]>,
): void => {
	const key = getLocalStorageKey(invitationId);
	const assessmentRecord = getLocalStorage<IAssessmentRecord>(key);
	const completedQuestions = assessmentRecord?.completedQuestions || [];

	setLocalStorage<IAssessmentRecord>(key, {
		currentQuestionIndex,
		completedQuestions: (questionId && !completedQuestions.includes(questionId)) ?
				[...completedQuestions, questionId] :
				[...completedQuestions],
		answerRecords: {
			...assessmentRecord?.answerRecords,
			...answerRecord,
		},
	});
};

/**
 * Removes assessment record with given invitation ID.
 * @param invitationId invitation ID
 */
export const removeAssessmentRecord = (invitationId: string): void => {
	removeLocalStorage(getLocalStorageKey(invitationId));
};

/**
 * Resumes assessment record into redux store with given invitation ID.
 * @param invitationId invitationID
 */
export const resumeAssessmentRecord = (
	invitationId: string,
): void => {
	const assessmentRecord = getAssessmentRecord(invitationId);
	if (!assessmentRecord) return;
	const { currentQuestionIndex, answerRecords, completedQuestions } = assessmentRecord;
	store.dispatch(setCurrentQuestionIndex(currentQuestionIndex));
	store.dispatch(updateAnswerRecords(answerRecords));
	store.dispatch(updateCompletedQuestions(completedQuestions));
};
