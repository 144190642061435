// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.success-jLgGAf {\n  height: 100%;\n}\n.smileIcon-hKyj8m {\n  margin-top: 100px;\n  font-size: 60px;\n}\n.successText-AW7VOd {\n  font-size: 20px;\n}\n.successBtn-hcKNkB {\n  margin-top: 60px;\n}\n.successBtn-hcKNkB .ant-btn-round {\n  min-width: 130px;\n  height: 50px;\n  font-size: 20px;\n}\n.successBtn-hcKNkB .ant-btn-round[disabled] {\n  background-color: #abb9f8;\n  border-color: #abb9f8;\n  color: white;\n}\n.successBtn-hcKNkB .ant-btn-round:hover {\n  border: none;\n  background-color: #c0cbfa;\n}\n", "",{"version":3,"sources":["webpack://src/pages/User/Register/Outcome/Outcome.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAA7F;EACC,YAAA;AAED;AACA;EACC,iBAAA;EACA,eAAA;AACD;AAEA;EACC,eAAA;AAAD;AAGA;EACC,gBAAA;AADD;AAAA;EAIY,gBAAA;EACA,YAAA;EACA,eAAA;AADZ;AAEG;EACC,yBAAA;EACA,qBAAA;EACA,YAAA;AAAJ;AAEG;EACC,YAAA;EACA,yBAAA;AAAJ","sourcesContent":["@import '~antd/es/style/themes/default.less';\n@import '~@/utils/utils.less';\n\n.success {\n\theight: 100%;\n}\n\n.smileIcon {\n\tmargin-top: 100px;\n\tfont-size: 60px;\n}\n\n.successText {\n\tfont-size: 20px;\n}\n\n.successBtn {\n\tmargin-top: 60px;\n\t:global {\n        .ant-btn-round {\n            min-width: 130px;\n            height: 50px;\n            font-size: 20px;\n\t\t\t&[disabled] {\n\t\t\t\tbackground-color: @primary-color-disable;\n\t\t\t\tborder-color: @primary-color-disable;\n\t\t\t\tcolor: white;\n\t\t\t}\n\t\t\t&:hover {\n\t\t\t\tborder: none;\n\t\t\t\tbackground-color: tint(@primary-color, 70%);\n\t\t\t}\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": "success-jLgGAf",
	"smileIcon": "smileIcon-hKyj8m",
	"successText": "successText-AW7VOd",
	"successBtn": "successBtn-hcKNkB"
};
export default ___CSS_LOADER_EXPORT___;
