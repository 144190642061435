import { ModalProps, Modal } from 'antd';
import { connect } from 'react-redux';
import type { IConnectState } from '@/interfaces/redux';
import { ICandidateBase } from '@/interfaces/candidate';
import { ECandidateActionTypes } from '@/constants/candidate';
import { login } from '@/store/candidate/actions';
import LoginForm from '@/pages/User/Login/components/LoginForm';
import styles from './LoginModal.less';

interface ILoginModalProps extends ModalProps{
	visible: boolean;
	hideModal: () => void;
	refresh?: () => void;
	candidate?: ICandidateBase;
	assessmentId: string;
	companyId: string;
}

const LoginModal: React.FC<ILoginModalProps> = (props) => {
	const { visible, hideModal, companyId } = props;
	const closeWindow = true;

	return (
		<Modal
			visible={visible}
			destroyOnClose={closeWindow}
			onCancel={hideModal}
			closable={closeWindow}
			afterClose={hideModal}
			footer={null}
			className={styles.loginWindow}
		>
			<LoginForm
				companyId={companyId}
				goTo={'assessment'}
			/>
		</Modal>
	);
};

export default connect(({ candidate, loading }: IConnectState ) => ({
	candidate: candidate.candidate,
	loadingStatus: loading[ECandidateActionTypes.login],
}), {
	login,
}
)(LoginModal);
