import React from 'react';
import { connect } from 'react-redux';
import { createIntl, RawIntlProvider } from 'react-intl';
import zh_CN from '@/locales/zh_CN';
import en_AU from '@/locales/en_AU';
import type { IConnectState } from '@/interfaces/redux';
import { ELocales } from '@/constants/intl';

const messages = {
	[ELocales.EN]: en_AU,
	[ELocales.ZH]: zh_CN,
};

interface IntlProviderProps {
	locale: ELocales;
}

const IntlProvider: React.FC<IntlProviderProps> = ({ locale, children }) => {
	const intl = createIntl({ locale, messages: messages[locale] });

	return (
		<RawIntlProvider value={intl}>
			{children}
		</RawIntlProvider>
	);
};

export default connect(({ intl }: IConnectState) => ({
	locale: intl.locale,
}))(IntlProvider);