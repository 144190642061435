import { isEmpty, isNumber } from 'lodash';
import type { IConvertedInvitation, IInvitationBase } from '@/interfaces/invitation';
import type { IConvertedSubmission, ISubmission } from '@/interfaces/submission';
import { getLocalStorage } from '@/utils/storage';
import { ELocalStorageKey } from '@/constants/storage';
/**
 * Calculates the number of incorrect questions of a submission based on the number of correct questions and the
 * number of answered questions
 * @param submission original submission
 * @returns the number of incorrect questions
 */
export const calculateIncorrectQuestionsOfSubmission = (submission: ISubmission): number => {
	const { answeredQuestions, correctQuestions } = submission;
	return isNumber(answeredQuestions) && isNumber(correctQuestions)
		? answeredQuestions - correctQuestions
		: 0;
};

/**
 * Calculates the correct rate of a submission based on the number of correct questions and the
 * number of answered questions
 * @param submission original submission
 * @param precision the number of digits displayed
 * @returns correct rate formmated to string
 */
export const calculateCorrectRateOfSubmission = (submission: ISubmission, questionCount?: number): number => {
	const { correctQuestions } = submission;
	return isNumber(questionCount) && questionCount !== 0 && isNumber(correctQuestions)
		? (correctQuestions / questionCount) * 100
		: -1;
};

/**
 * Converts raw submission response from backend to frontend format.
 * @param submission raw submission
 * @returns converted submission
 */
export const convertSubmissionResponse = (submission: ISubmission, questionCount?: number): IConvertedSubmission => {
	return {
		...submission,
		incorrectQuestions: calculateIncorrectQuestionsOfSubmission(submission),
		correctRate: calculateCorrectRateOfSubmission(submission, questionCount),
	};
};

/**
 * Check if an invitation is allowed to be retaken.
 * @param invitation invitation to check
 * @returns true if attemptsLeft is greater than 0 or is not specified
 */
export const checkCanRetake = (invitation: IInvitationBase): boolean => {
	const { attemptsLeft } = invitation;
	return !(isNumber(attemptsLeft) && attemptsLeft <= 0);
};

/**
 * Converts raw invitation response from backend to frontend format.
 * @param invitation raw invitation
 * @returns formatted invitation
 */
export const convertInvitationResponse = (
	invitation: IConvertedInvitation,
): IConvertedInvitation => {
	const { submissions, currentAttempt, assessment } = invitation;
	const { questionCount } = assessment;
	invitation.submissions = submissions.map(submission => convertSubmissionResponse(submission, questionCount));
	if (!!currentAttempt && !isEmpty(currentAttempt)) {
		invitation.currentAttempt = convertSubmissionResponse(currentAttempt, questionCount);
	}
	invitation.canRetake = checkCanRetake(invitation);
	return invitation;
};

/**
 * Gets current invitation
 * @returns current invitation
 */
export const getCurrentInvitation = () : IInvitationBase | null => {
	return getLocalStorage<IInvitationBase>(ELocalStorageKey.INVITATION);
};