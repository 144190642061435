export default {
	'common.complete': 'Complete',
	'layout.basic.header.logo': 'OA System',
	'invitation.invalid': 'Assessment Invitation Has Expired',
	'invitation.completed': 'You have completed the assessment',
	'assessment.cover.header': 'Assessment: {assessmentName}',
	'assessment.cover.start': 'Start→',
	'assessment.cover.reminder': '*There may be a time limit for individual question in this test, please be aware of the time available to answer them.',
	'assessment.cover.resume': 'Resume Assessment',
	'assessment.cover.start.confirm': 'You have {attemptsLeft} time(s) left to take the assessment，do you want to start?',
	'assessment.fields.name': 'Assessment Name',
	'assessment.fields.description': 'Assessment Description',
	'assessment.fields.difficulty': 'Assessment Difficulty',
	'assessment.fields.startDate': 'Start Date',
	'assessment.fields.endDate': 'End Date',
	'assessment.fields.duration': 'Time Limit',
	'assessment.fields.timeLimitEachQuestion': 'Time Limit Each Question',
	'assessment.fields.trialsLimit': 'Trials Limit',
	'assessment.fields.questionCount': 'Total Questions',
	'assessment.fields.questionCount.default': 'All questions in the question set',
	'assessment.fields.totalPoints': 'Total Points',
	'assessment.fields.unlimited': 'Unlimited',
	'assessment.questionTimeLimit': 'Remaining Time',
	'assessment.submission.title': 'Are you sure you want to submit the questions? You will not be able to make any changes once submitted.',
	'assessment.submission.review': 'Maybe later',
	'assessment.submission.confirm': 'Sure, submit',
	'assessment.timeout.title': 'The session has Expired',
	'assessment.timeout.company.contact': 'For further inquiries, please contact HR at: ',
	'assessment.timeout.confirm': 'Confirm and return',
	'assessment.timeout.customer.contact': 'Contact customer service:',
	'assessment.link.expired': 'The Assessment Link Has Expired',
	'submission.title': 'Thank you for taking this test',
	'submission.assignmentTitle': 'Thank you for completing the assessment',
	'submissionCard.correctRate': 'Correct Rate',
	'submissionCard.answered': 'Answered: ',
	'submissionCard.complete': 'Question completed: ',
	'submissionCard.totalTimeSpent': 'Time Spent: ',
	'submissionCard.correct': 'Correct: ',
	'submissionCard.incorrect': 'Incorrect: ',
	'submissionCard.score': 'Score: ',
	'submissionCard.totalScore': 'Total score: ',
	'submissionCard.review': 'Review',
	'submission.retake': 'Retake Test',
	'question.title': 'Question',
	'question.caseStudy.title': 'Case Study - {totalQuestion} questions {subquestionId}/{totalQuestion}',
	'question.caseStudy.subquestion.title': 'Subquestion {subquestionId}: ',
	'question.questionCount': 'Total Question',
	'question.timer': 'Timer',
	'question.back': 'Back',
	'question.next': 'Next',
	'question.finish': 'Finish',
	'question.return': 'Return',
	'question.option': 'Options',
	'question.review.correct': 'Correct',
	'question.review.incorrect': 'Incorrect',
	'question.review.correctOption': 'Correct Option: ',
	'question.review.analysis': 'Question Analysis: ',
	'questionType.multipleChoice': 'Multiple Choice',
	'questionType.checkboxes': 'Checkboxes',
	'questionType.caseStudy': 'Case Study',
	'login.button': 'Sign In',
	'login.signup': 'Sign Up',
	'login.placeholder': 'Start typing...',
	'login.forgotPassword': 'Forgot Password？',
	'login.rememberMe': 'Keep me login',
	'login.email': 'Email',
	'login.phone': 'Phone',
	'login.email.label': 'Email',
	'login.email.required': 'Please input your email',
	'login.password.label': 'Password',
	'login.password.required': 'Please input your password',
	'login.title': 'Millions of questions, simplify your recruitment process',
	'login.subtitle': 'Enter your details to proceed further',
	'login.noAccount': 'No account?',
	'login.loginWith': 'Or sign in with',
	'login.errorMessage': 'Incorrect email/password',
	'login.mobile.errorMsg': 'Invalid mobile number',
	'login.mobile.notFoundMsg': 'Mobile number not found, please sign up first',
	'login.mobilePlaceholder': 'Please enter mobile number',
	'login.codePlaceholder': 'Please enter verification code',
	'login.requireMobile.errorMsg': 'Mobile cannot be empty',
	'login.requireCode.errorMsg': 'Code cannot be empty',
	'login.sendCodeBtn.waitingText': 's Send again',
	'login.sendCodeBtn.text': 'Send code',
	'login.email.error.invalidInput': 'Please enter a valid email',
	'login.password.placeholder': 'Please enter your passowrd',
	'login.success': 'Sign in successfully!',
	'login.failure': 'Something went wrong, please try again',
	'register.title': 'Millions of questions, simplify your recruitment process',
	'register.email': 'Email',
	'register.password.placeholder': 'Please enter your passowrd',
	'register.confirmedPassword.placeholder': 'Please enter the password again',
	'register.password.error.emptyPassword': 'Please enter a password',
	'register.password.error.passwordFormat': '6-30 character including letter, numbers and special characters, at least one upper and one lower case letter',
	'register.confirmPassword.error': 'The password is not matching',
	'register.password.error.passwordLength': 'Your password must have 6-30 characters',
	'register.password.error.passwordCase': 'You must include at least one upper case and one lower case characters',
	'register.email.error.emptyInput': 'Please enter an email',
	'register.email.error.invalidInput': 'Please enter a valid email',
	'register.firstName.placeholder': 'First name',
	'register.lastName.placeholder': 'Last name',
	'register.name.invalidInput': 'Please enter valid first name and last name',
	'register.name.error.required': 'Please enter your first name and last name',
	'register.email.placeholder': 'Please enter your Email',
	'register.email.error.existing': 'This email is already in our system',
	'register.emailVerification.placeholder': 'Please enter the verification code',
	'register.emailVerification.send': 'Send Code',
	'register.emailVerification.sent': 'Sent: {seconds}s',
	'register.emailVerification.resend': 'Resend',
	'register.emailVerification.error.required': 'Please enter the verification code',
	'register.emailVerification.error.notMatch': 'Code not match. Please try again',
	'register.sendCodeBtn.text': 'Send code',
	'register.sendCodeBtn.waitingText': 's Send again',
	'register.success.text': 'Sign up Successfully! Return to login page',
	'register.success.btn': 'Login',
	'register.fail.text': 'Sorry! Failed to sign up due to: ',
	'register.fail.guide': 'You can refresh page and try again!',
	'register.fail.btn': 'Refresh page',
	'register.button': 'Sign Up',
	'register.terms': 'By clicking this you agree the <a>terms and conditions</a>',
	'register.terms.error': 'Please check and confirm OA system terms and policy',
	'register.haveAccount': 'Already have an account. ',
	'register.login.link': 'Login here →',
	'forgetPassword.send': 'Send',
	'forgetPassword.resetPasswordLinkDescription1': 'The link has been sent to your email',
	'forgetPassword.resetPasswordLinkDescription2': 'Please check your email',
	'forgetPassword.title': 'Reset password link',
	'forgetPassword.email.placeholder': 'lease enter your registered email',
	'forgetPassword.email.error.unregistered': '* Looks like the email is not in our system, please check your spell or ',
	'forgetPassword.email.error.unregistered.link': 'Signup',
	'resetPassword.password.error.usedPassword': 'Your new password must be different from the old one',
	'resetPassword.password.placeholder': 'Please enter a new password',
	'resetPassword.successMessage': 'Reset password succeeds. Sign in now',
	'resetPassword.signIn': 'Sign In',
	'resetPassword.title': 'Reset',
	'resetPassword.send': 'Reset',
};
