import React from 'react';
import classnames from 'classnames';
import { Progress } from 'antd';
import { isNumber } from 'lodash';
import styles from './CircularProgress.less';

const progressFormatter = (percent?: number): React.ReactNode => {
	return isNumber(percent) && percent >= 0
		? percent.toFixed(0) + '%'
		: '--';
};

interface ICircularProgressProps {
	width?: number;
	percent?: React.ReactNode;
	text?: React.ReactNode;
	textMargin?: number;
}

/**
 * A circular progress bar used for showing progress in the format of percentage.
 * Undefined or negative percentage is displayed as '--'.
 * @param width width of the circle
 * @param percent percent to be displayed.
 * @param text description text below the circle
 * @param textMargin margin between the circle and the text
 */
const CircularProgress: React.FC<ICircularProgressProps> = ({
	width,
	percent,
	text,
	textMargin = 0,
	...props
}) => {
	return (
		<div className={classnames('color-primary', styles.container)}>
			<Progress
				type="circle"
				width={width}
				strokeColor={{
					'0%': '#fe8986',
					'100%': '#1676ff',
				}}
				format={progressFormatter}
				percent={isNumber(percent) ? percent : void 0}
				{...props}
			/>
			<div style={{ marginTop: textMargin }} className="font-size-12">
				{text}
			</div>
		</div>
	);
};

export default CircularProgress;