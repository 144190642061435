/** Request 网络请求工具 更详细的 api 文档: https://github.com/umijs/umi-request */
import { extend } from 'umi-request';

const SERVER_HOST = process.env.REACT_APP_SERVER_HOST;

const codeMessage: { [status: number]: string } = {
	400: 'Bad Request',
	401: 'Unauthorized',
	403: 'Forbidden',
	404: 'Not Found',
	500: 'Internal Server Error',
	503: 'Service Unavailable',
};

/**
 * Data structure for HTTP responses.
 * @property {Response} response Original response information.
 * @property {T} data Data from a successful response.
 * @property {string} error Error text from a failed response.
 */
export interface IResponseEntity<T = unknown> {
	response: Response;
	data?: T;
	error?: string;
}

/**
 * Shows error text in a notification.
 * Returns a new IResponseEntity containing the error text.
*/
const errorHandler = (errorResponseEntity: IResponseEntity<string>): IResponseEntity<never> => {
	// eslint-disable-next-line
	const errorText = getErrorText(errorResponseEntity);
	return {
		response: errorResponseEntity.response,
		error: errorResponseEntity.data,
	};
};

const getErrorText = (error: IResponseEntity<string>): string => {
	const { response } = error;

	if (!response) {
		return 'Cannot connect to server';
	}

	const { status } = response;
	return codeMessage[status] || `Error code: ${status}`;
};

// Configures request
const request = extend({
	prefix: SERVER_HOST + '/api/v1',
	errorHandler,
	credentials: 'include', // Enables cookies for checking verification code in sign up process.
	getResponse: true, // Returns response in each request in order to get http status.
});

// Adds token to the 'Authorization' header in each request
request.interceptors.request.use((url, options) => {
	const token = localStorage.getItem('token') || '';
	const { headers } = options;
	return {
		url,
		options: {
			...options,
			headers: {
				...headers,
				Authorization: token,
			}
		},
	};
});

export default request;
