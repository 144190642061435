import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import IntlProvider from './components/IntlProvider';
import './index.less';

ReactDOM.render(
	<Provider store={store}>
		<IntlProvider>
			<App />
		</IntlProvider>
	</Provider>,
	document.getElementById('root')
);

reportWebVitals();
