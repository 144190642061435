import React from 'react';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router';
import { SmileOutlined, FrownOutlined } from '@ant-design/icons';
import Button from '@/components/Button/Button';
import { useIntl } from 'react-intl';
import styles from './Outcome.less';
import { useParams } from 'react-router-dom';
import UserLayout from '@/layouts/UserLayout';

const Outcome: React.FC = () => {
	const intl = useIntl();
	const history = useHistory();
	const outcome = useParams()['outcome'];
	const companyId = useParams()['id'];
	const isSuccessful = outcome==='success'? true : false;

	return (
		<UserLayout>
			<Row className={styles.success} justify="center">
				<Col>
					<Row justify="center">
						<Col className={styles.smileIcon}>
							{ isSuccessful ? <SmileOutlined /> : <FrownOutlined /> }
						</Col>
					</Row>
					<Row justify="center" gutter={[0, 50]}>
						<Col className={styles.successText}>
							{
								isSuccessful ? <p>{intl.formatMessage({ id: 'register.success.text' })}</p> : (
									<>
										<p>{intl.formatMessage({ id: 'register.fail.text' }) + 'error'}</p>
										<p>{intl.formatMessage({ id: 'register.fail.guide' })}</p>
									</>
								)
							}
						</Col>
					</Row>
					<Row justify="center">
						<Col className={styles.successBtn}>
							{
								<Button
									reverseColor
									onClick={() => {history.push(`/${isSuccessful? 'login' : 'register'}/${companyId}`);}}
								>
									{ intl.formatMessage({ id: isSuccessful? 'register.success.btn' : 'register.fail.btn' })}
								</Button>
							}
						</Col>
					</Row>
				</Col>
			</Row>
		</UserLayout>
	);
};

export default Outcome;