import type { ReducersMapObject } from 'redux';
import { createReducer } from '@/utils/redux';
import { ILoadingStoreState, ISetLoadingAction } from '@/interfaces/loading';
import { ELoadingActionTypes } from '@/constants/loading';

const initialState: ILoadingStoreState = {};

const setLoading = (state: ILoadingStoreState, { payload }: ISetLoadingAction): ILoadingStoreState => {
	if (!payload) return state;
	const { path, value, error } = payload;

	return {
		...state,
		[path]: {
			isLoading: value,
			error: error || '',
		},
	};
};

const reducers: ReducersMapObject = {
	[ELoadingActionTypes.setLoading]: setLoading,
};

export default createReducer(initialState, reducers);