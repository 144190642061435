import React, { useEffect, useState } from 'react';
import styles from './CountDownTimer.less';
import { FormattedMessage } from 'react-intl';

interface CountDownTimerProps {
	handleTimeIsUp: () => void,
	timeLimitEachQuestion: number,
	updateQuestionEndTime: (questionEndTime: number) => void,
	questionEndTime?: number,
	editAnswer: boolean,
}

const CountDownTimer: React.FC<CountDownTimerProps> = ({
	handleTimeIsUp,
	timeLimitEachQuestion,
	questionEndTime,
	editAnswer
}) => {
	const getRemainingTime = () => {
		return questionEndTime
			? Math.ceil((questionEndTime - Date.now()) / 1000)
			: timeLimitEachQuestion;
	};

	const [timer, setTimer] = useState(() =>
		questionEndTime ? getRemainingTime() : timeLimitEachQuestion
	);

	let timerRefreshInterval: NodeJS.Timeout;

	const countdown = (interval: number, setTimer: React.Dispatch<React.SetStateAction<number>>) => {
		return new Promise((resolve) => {
			const countDownTimer = () => {
				const remainingTime = getRemainingTime();
				if (remainingTime >= 0) {
					setTimer(remainingTime);
				} else {
					clearInterval(timerRefreshInterval);
					resolve(true);
				}
			};
			// set interval 200ms to avoid jumping
			timerRefreshInterval = setInterval(countDownTimer, 200);
		});
	};

	useEffect(() => {
		const timeLeft = countdown(timeLimitEachQuestion, setTimer);
		timeLeft.then(() => {
			if (!editAnswer) {
				handleTimeIsUp();
			}
		});
		return () => {
			// clear interval after unmounting
			clearInterval(timerRefreshInterval);
		};
	}, [handleTimeIsUp, timeLimitEachQuestion]);

	return (
		<div className={styles.countDownTimer}>
			<div className={styles.title}><FormattedMessage id="assessment.questionTimeLimit" /></div>
			<div className={styles.countDown}>{timer}<span className={styles.seconds}>s</span></div>
		</div>
	);
};

export default CountDownTimer;

