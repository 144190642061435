import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import styles from './CorrectnessTag.less';

interface ICorrectnessTagProps {
	isCorrect?: boolean;
}

const CorrectnessTag: React.FC<ICorrectnessTagProps> = ({
	isCorrect
}) => {
	return (
		<div className={classnames(styles.correctnessTag, isCorrect? styles.correct : styles.incorrect, 'font-size-16')}>
			{isCorrect? <FormattedMessage id="question.review.correct" /> : <FormattedMessage id="question.review.incorrect" />}
		</div>
	);
};

export default CorrectnessTag;
