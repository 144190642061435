import type { IActionCreator } from '@/interfaces/redux';
import type { IGetQuestionRequest, IQuestionBase, ISaveSelectedOptionsRequest } from '@/interfaces/question';
import { EQuestionActionTypes } from '@/constants/question';

export const insertQuestion: IActionCreator<IQuestionBase> = (question) => ({
	type: EQuestionActionTypes.insertQuestion,
	payload: question,
});

export const updateQuestion: IActionCreator<Record<number, IQuestionBase>> = (questionRecord) => ({
	type: EQuestionActionTypes.updateQuestion,
	payload: questionRecord,
});

export const setCurrentQuestionIndex: IActionCreator<number> = (questionIndex) => ({
	type: EQuestionActionTypes.setCurrentQuestionIndex,
	payload: questionIndex,
});

export const startAssessment: IActionCreator<string> = (invitationId, callback) => ({
	type: EQuestionActionTypes.startAssessment,
	payload: invitationId,
	callback,
});

export const getQuestion: IActionCreator<IGetQuestionRequest> = (getQuestionRequest) => ({
	type: EQuestionActionTypes.fetchQuestion,
	payload: getQuestionRequest,
});

export const getQuestionReview: IActionCreator<IGetQuestionRequest> = (getQuestionRequest) => ({
	type: EQuestionActionTypes.fetchQuestionReview,
	payload: getQuestionRequest,
});

export const completeQuestion: IActionCreator<string> = (questionId) => ({
	type: EQuestionActionTypes.completeQuestion,
	payload: questionId,
});

export const updateCompletedQuestions: IActionCreator<Array<string>> = (completedQuestions) => ({
	type: EQuestionActionTypes.updateCompletedQuestions,
	payload: completedQuestions,
});

export const updateQuestionEndTime: IActionCreator<number> = (questionEndTime) => ({
	type: EQuestionActionTypes.updateQuestionEndTime,
	payload: questionEndTime,
});

export const updateAnswerRecords: IActionCreator<Record<number, number[] | undefined>> = (answerRecord) => ({
	type: EQuestionActionTypes.updateAnswerRecords,
	payload: answerRecord,
});

export const clearAnswerRecords: IActionCreator = () => ({
	type: EQuestionActionTypes.clearAnswerRecords,
});

export const saveSelectedOptions: IActionCreator<ISaveSelectedOptionsRequest> = (saveSelectedOptionsRequest) => ({
	type: EQuestionActionTypes.saveSelectedOptions,
	payload: saveSelectedOptionsRequest,
});

export const saveCurrentSelectedOptions: IActionCreator<never> = () => ({
	type: EQuestionActionTypes.saveCurrentSelectedOptions,
});
