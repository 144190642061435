import type { IActionCreator } from '@/interfaces/redux';
import type { IInvitationBase } from '@/interfaces/invitation';
import type { IAssessmentBase } from '@/interfaces/assessment';
import { EInvitationActionTypes, EInvitationStatus } from '@/constants/invitation';

export const setInvitation: IActionCreator<IInvitationBase> = (invitation) => ({
	type: EInvitationActionTypes.setInvitation,
	payload: invitation,
});

export const fetchInvitationByToken: IActionCreator<string> = (token) => ({
	type: EInvitationActionTypes.fetchInvitationByToken,
	payload: token,
});

export const completeAssessment: IActionCreator<string> = (invitationId) => ({
	type: EInvitationActionTypes.completeAssessment,
	payload: invitationId,
});

export const finishAssessment: IActionCreator<string> = (invitationId) => ({
	type: EInvitationActionTypes.finishAssessment,
	payload: invitationId,
});

export const submitAssessment: IActionCreator<string> = (invitationId, callback) => ({
	type: EInvitationActionTypes.submitAssessment,
	payload: invitationId,
	callback,
});

export const setTotalTimeSpent: IActionCreator<number> = (totalTimeSpent) => ({
	type: EInvitationActionTypes.setTotalTimeSpent,
	payload: totalTimeSpent,
});

export const setInvitationStatus: IActionCreator<EInvitationStatus> = (status) => ({
	type: EInvitationActionTypes.setStatus,
	payload: status,
});

export const setQuestionCount: IActionCreator<number> = (questionCount) => ({
	type: EInvitationActionTypes.setQuestionCount,
	payload: questionCount,
});

export const fetchAssessmentByToken: IActionCreator<string> = (token) => ({
	type: EInvitationActionTypes.fetchAssessmentByToken,
	payload: token,
});

export const setAssessment: IActionCreator<IAssessmentBase> = (assessment) => ({
	type: EInvitationActionTypes.setAssessment,
	payload: assessment,
});

export const setAttemptsLeft: IActionCreator<string> = (attemptsLeft) => ({
	type: EInvitationActionTypes.setAttemptsLeft,
	payload: attemptsLeft,
});