import React, { useState, useEffect } from 'react';
import { formatDuration } from '@/utils/date';
import { isUndefined } from 'lodash';

const ONE_SECOND = 1000;

interface ITimerProps {
	initialTime: number;
	usedTime?: number;
	onTimeUp?: () => void;
}

/**
 * A timer of format HH:mm:ss
 * @param initialTime initial time in seconds
 * @param onTimeUp the callback to be invoked when the timer counts down to zero
 */
const Timer: React.FC<ITimerProps> = (props) => {
	const { initialTime, onTimeUp, usedTime } = props;
	const [timer, setTimer] = useState<number>(initialTime);

	function timeLeft() {
		if (!isUndefined(usedTime)) {
			return initialTime-usedTime;
		}
		else {
			return initialTime;
		}
	}

	useEffect(()=>{
		setTimer(timeLeft);
	}, [usedTime]);

	useEffect(() => {
		if (timer <= 0) {
			onTimeUp?.();
			return;
		}
		const timeOut: NodeJS.Timeout = setTimeout(() => {
			setTimer(timer - 1);
		}, ONE_SECOND);

		return () => {
			if (!!timeOut) clearTimeout(timeOut);
		};
	}, [timer, onTimeUp]);

	return (
		<span>
			{formatDuration(timer)}
		</span>
	);
};

export default Timer;

