import React, { useState } from 'react';
import { Tabs, Form, message } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Button from '@/components/Button/Button';
import ProForm, { ProFormText, ProFormCheckbox, ProFormCaptcha, ProFormSelect } from '@ant-design/pro-form';
import { ICandidateBase } from '@/interfaces/candidate';
import { useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { getSessionStorage } from '@/utils/storage';
import { ESessionStorageKey } from '@/constants/storage';
import { ECandidateActionTypes } from '@/constants/candidate';
import type { ILoadingStatus } from '@/interfaces/loading';
import type { IConnectState } from '@/interfaces/redux';
import styles from './LoginForm.less';
import userStyles from '@/pages/User/User.less';

interface ILoginFormProps{
	refresh?: () => void;
	companyId?: string;
	goTo: string;
	loading?: ILoadingStatus;
}

const EMAIL_TAB = 'email';
const MOBILE_TAB = 'mobile';
const RESEND_INTERVAL = 59;

const loginActionMap = {
	'email': {
		'type': 'candidate/login',
		'fields': ['email', 'password', 'rememberMe']
	},
	'mobile': {
		'type': 'candidate/mobileLogin',
		'fields': ['countryCode', 'mobile', 'verificationCode']
	}
};

const LoginForm: React.FC<ILoginFormProps> = (props) => {
	const { companyId, goTo } = props;
	const history = useHistory();
	const dispatch = useDispatch();
	const intl = useIntl();
	const [tabIndex, setTabIndex] = useState<string>(EMAIL_TAB);
	const [hasCodeSent, setHasCodeSent] = useState(false);
	const [form] = ProForm.useForm();

	const checkLoginResult = async() => {
		const currentCandidate: ICandidateBase | null = getSessionStorage(ESessionStorageKey.CANDIDATE);
		if (currentCandidate) {
			message.success(intl.formatMessage({ id: 'login.success' }));
			history.push(`/${goTo}`);
		} else {
			message.error(intl.formatMessage({ id: 'login.failure' }));
		}
	};

	const mobileLogin = async() => {
		const { countryCode, mobile, verificationCode } = form.getFieldsValue(loginActionMap[tabIndex].fields);
		await dispatch({
			type: loginActionMap[tabIndex].type,
			payload: {
				companyId,
				countryCode,
				mobile,
				verificationCode,
			}
		});
		setTimeout(() => {
			checkLoginResult();
		}, 350);
	};

	const emailLogin = async() => {
		const { email, password, rememberMe } = form.getFieldsValue(loginActionMap[tabIndex].fields);
		await dispatch({
			type: loginActionMap[tabIndex].type,
			payload: {
				email,
				password,
				rememberMe,
				companyId
			}
		});
		setTimeout(() => {
			checkLoginResult();
		}, 350);
	};

	const sendMobileCode = async (): Promise<void> => {
		setHasCodeSent(true);
		setTimeout(() => setHasCodeSent(false), RESEND_INTERVAL*1000);

		const { countryCode, mobile: phone } = form.getFieldsValue();
		await dispatch({
			type: ECandidateActionTypes.sendMobileCode,
			payload: { countryCode, phone }
		});
	};

	return (
		<div className={classnames(styles.loginForm, userStyles.authForm)}>
			<div className={classnames('display-flex', 'justify-content-between', 'align-items-center', styles.wrapper)}>
				<div>
					<h2 className={userStyles.title}>
						<FormattedMessage id="login.title" />
					</h2>
					<ProForm
						initialValues={{
							rememberMe: true,
						}}
						submitter={false}
						form={form}
					>
						<Tabs
							defaultActiveKey={tabIndex}
							className={styles.tab}
							onChange={(tab) => setTabIndex(tab)}
						>
							<Tabs.TabPane tab={<FormattedMessage id="login.email" />} key={EMAIL_TAB}>
								<ProFormText
									className={styles.placeholder}
									name="email"
									placeholder={intl.formatMessage({ id: 'login.email.label' })}
									fieldProps={{
										size: 'large',
										allowClear: false,
									}}
									rules={tabIndex === EMAIL_TAB ? [
										{
											required: true,
											message: <FormattedMessage id="login.email.required" />
										},
										{
											type: 'email',
											message: intl.formatMessage({ id: 'login.email.error.invalidInput' })
										}
									] : undefined}
									validateTrigger="onBlur"
								/>
								<ProFormText.Password
									className={styles.placeholder}
									name="password"
									fieldProps={{
										size: 'large',
										type: 'password',
										autoComplete: 'on',
										allowClear: false,
										iconRender: visible => (
											visible ? <img src="/icons/eye_blue_reverse.svg" alt="visible icon" /> : <img src="/icons/eye_blue.svg" alt="invisible icon" />
										),
									}}
									placeholder={intl.formatMessage({ id: 'login.password.label' })}
									rules={[
										{
											required: true,
											message: <FormattedMessage id="login.password.placeholder" />,
											warningOnly: tabIndex !== EMAIL_TAB
										},
									]}
								/>
								<div className={classnames('display-flex', 'justify-content-between', styles.frogotPassword)}>
									<Link to="/candidate/forgetPassword">
										<FormattedMessage id="login.forgotPassword" />
									</Link>
									<ProFormCheckbox name="rememberMe" fieldProps={{ className: styles.bold }}>
										<FormattedMessage id="login.rememberMe" />
									</ProFormCheckbox>
								</div>
							</Tabs.TabPane>
							<Tabs.TabPane tab={<FormattedMessage id="login.phone" />} key={MOBILE_TAB}>
								<Form.Item className={styles.mobile} shouldUpdate>
									{
										(form) => (
											<ProFormCaptcha
												className={styles.placeholder}
												fieldProps={{
													size: 'large',
													prefix:
														<ProFormSelect name="countryCode"
															className={styles.countryCode}
															options={[
																{ label: '+61', value: '61' },
																{ label: '+86', value: '86' },
															]}
															initialValue="61"
															fieldProps={{
																bordered: false,
																allowClear: false
															}}
														/>
												}}
												captchaProps={{
													size: 'large',
													disabled: hasCodeSent || form.getFieldError('mobile').length > 0 || !form.getFieldValue('mobile')
												}}
												// The name of the phone number, which is injected by onGetCaptcha
												phoneName="phone"
												name="mobile"
												countDown={RESEND_INTERVAL}
												rules={tabIndex === MOBILE_TAB ? [
													{
														required: true,
														message: intl.formatMessage({ id: 'login.requireMobile.errorMsg' }),
														warningOnly: tabIndex !== MOBILE_TAB
													},
													{
														type: 'number',
														message: intl.formatMessage({ id: 'login.mobile.errorMsg' }),
														transform: (v: string) => Number(v)
													},
													{
														min: 9,
														message: intl.formatMessage({ id: 'login.mobile.errorMsg' })
													},
													{
														max: 11,
														message: intl.formatMessage({ id: 'login.mobile.errorMsg' })
													},
												] : undefined}
												validateFirst
												placeholder={intl.formatMessage({ id: 'login.mobilePlaceholder' })}
												onGetCaptcha={sendMobileCode}
												captchaTextRender={(timing, count) => timing ? `${count}${intl.formatMessage({ id: 'login.sendCodeBtn.waitingText' })}` : intl.formatMessage({ id: 'login.sendCodeBtn.text' })}
											/>
										)
									}
								</Form.Item>
								<ProFormText
									name="verificationCode"
									className={styles.placeholder}
									rules={tabIndex === MOBILE_TAB ? [
										{
											required: true,
											message: intl.formatMessage({ id: 'login.requireCode.errorMsg' }),
										}
									] : undefined}
									fieldProps={{
										placeholder: intl.formatMessage({ id: 'login.codePlaceholder' }),
										size: 'large',
									}}
								/>
							</Tabs.TabPane>
						</Tabs>
						<div className={classnames('display-flex', 'justify-content-center', 'align-items-center', userStyles.submit)}>
							<Button
								size="xlarge"
								shape="round"
								color="blue"
								reverseColor
								type="primary"
								htmlType="submit"
								onClick={tabIndex==='email'? emailLogin : mobileLogin}
							>
								<FormattedMessage id="login.button" />
							</Button>
						</div>
						<div className={classnames('display-flex', 'justify-content-center', 'align-items-center', 'signup')}>
							<span>
								<FormattedMessage id="login.noAccount" />
							</span>
							<Link to={`/register/${companyId}`} className={styles.signupLink}>
								<FormattedMessage id="login.signup" />
							</Link>
						</div>
					</ProForm>
				</div>
			</div>
		</div>
	);
};

export default connect(({ candidate, loading }: IConnectState ) => ({
	candidate: candidate.candidate,
	loading: loading[ECandidateActionTypes.login] || loading[ECandidateActionTypes.sendMobileCode] || loading[ECandidateActionTypes.mobileLogin]
}),
)(LoginForm);