import React from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import { CaretDownOutlined, GlobalOutlined } from '@ant-design/icons';
import { setLocale } from '@/store/intl/actions';
import { useIntl } from 'react-intl';
import type { MenuInfo } from 'rc-menu/lib/interface';
import type { IActionCreator } from '@/interfaces/redux';
import { ELocales } from '@/constants/intl';
// import CustomIcon from '../CustomIcon/CustomIcon';
import styles from './SelectLang.less';

interface SelectLangProps {
	setLocale: IActionCreator<ELocales>;
}

const SelectLang: React.FC<SelectLangProps> = ({ setLocale }) => {
	const changeLanguage = (menuInfo: MenuInfo): void => {
		const newLocale = menuInfo.key.toString() as ELocales;
		setLocale(newLocale);
	};

	const intl = useIntl();
	const keyToText = () => {
		switch (`${intl.locale}`) {
			case 'zh-CN':
				return '中文';
			case 'en-AU':
				return 'English';
			default:
				return '中文';
		}
	};

	const menu = (
		<Menu className={styles.menu}>
			<Menu.Item key={ELocales.EN} onClick={changeLanguage}>
				<span role="img" aria-label="English">
					🇦🇺
				</span>{' '}
				English
			</Menu.Item>
			<Menu.Item key={ELocales.ZH} onClick={changeLanguage}>
				<span role="img" aria-label="Chinese">
					🇨🇳
				</span>{' '}
				简体中文
			</Menu.Item>
		</Menu>
	);

	return (
		<div className={styles.container}>
			<div className={styles.icon} >
				<GlobalOutlined />
			</div>
			<span className={styles.langText}>
				{keyToText()}
			</span>
			<Dropdown overlay={menu} className={styles.dropDown}>
				<CaretDownOutlined />
			</Dropdown>
		</div>
	);
};

export default connect(null, {
	setLocale,
})(SelectLang);