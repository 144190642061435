import { createReducer } from '@/utils/redux';
import { omit } from 'lodash';
import type { Reducer, ReducersMapObject } from 'redux';
import type { IAction } from '@/interfaces/redux';
import type { IQuestionBase, IQuestionStoreState } from '@/interfaces/question';
import { EQuestionActionTypes } from '@/constants/question';

export const INITIAL_QUESTION_INDEX = 0;

const initialState: IQuestionStoreState = {
	currentQuestionIndex: INITIAL_QUESTION_INDEX,
	completedQuestions: [],
};

const insertQuestion: Reducer<IQuestionStoreState, IAction<IQuestionBase>> = (
	state = initialState,
	{ payload }
) => {
	return payload ? {
		...state,
		questions: {
			...state.questions,
			[state.currentQuestionIndex]: payload,
		},
	} : state;
};

const updateQuestion: Reducer<IQuestionStoreState, IAction<Record<number, IQuestionBase>>> = (
	state = initialState,
	{ payload }
) => {
	return payload ? {
		...state,
		questions: {
			...state.questions,
			...payload,
		},
	} : state;
};

const setCurrentQuestionIndex: Reducer<IQuestionStoreState, IAction<number>> = (
	state= initialState,
	{ payload = INITIAL_QUESTION_INDEX }
) => {
	return {
		...state,
		currentQuestionIndex: payload,
	};
};

const updateQuestionEndTime: Reducer<IQuestionStoreState, IAction<number>> = (
	state= initialState,
	{ payload }
) => {
	return {
		...state,
		questionEndTime: payload,
	};
};

const updateAnswerRecords: Reducer<IQuestionStoreState, IAction<Record<number, number[]>>> = (
	state = initialState,
	{ payload }
) => {
	return {
		...state,
		answerRecords: {
			...state?.answerRecords,
			...payload,
		},
	};
};

const clearAnswerRecords: Reducer<IQuestionStoreState> = (state) => {
	return omit(state, ['answerRecords', 'questions']);
};

const completeQuestion: Reducer<IQuestionStoreState, IAction<string>> = (
	state = initialState,
	{ payload }
) => {
	return payload && !state.completedQuestions.includes(payload) ? {
		...state,
		completedQuestions: [...state.completedQuestions, payload]
	} : state;
};

const updateCompletedQuestions: Reducer<IQuestionStoreState, IAction<Array<string>>> = (
	state = initialState,
	{ payload }
) => {
	return payload ? {
		...state,
		completedQuestions: payload
	} : state;
};

const reducers: ReducersMapObject = {
	[EQuestionActionTypes.insertQuestion]: insertQuestion,
	[EQuestionActionTypes.updateQuestion]: updateQuestion,
	[EQuestionActionTypes.setCurrentQuestionIndex]: setCurrentQuestionIndex,
	[EQuestionActionTypes.updateAnswerRecords]: updateAnswerRecords,
	[EQuestionActionTypes.clearAnswerRecords]: clearAnswerRecords,
	[EQuestionActionTypes.completeQuestion]: completeQuestion,
	[EQuestionActionTypes.updateCompletedQuestions]: updateCompletedQuestions,
	[EQuestionActionTypes.updateQuestionEndTime]: updateQuestionEndTime,
};

export default createReducer(initialState, reducers);