import { all, fork } from 'redux-saga/effects';
import watchInvitation from './invitation/sagas';
import watchQuestion from './question/sagas';
import watchCandidate from './candidate/sagas';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';

export default function* rootSaga(): Generator<AllEffect<ForkEffect<void>>> {
	yield all([
		fork(watchInvitation),
		fork(watchQuestion),
		fork(watchCandidate)
	]);
}