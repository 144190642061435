import { createReducer } from '@/utils/redux';
import type { Reducer, ReducersMapObject } from 'redux';
import type { IAction } from '@/interfaces/redux';
import { ELocales, EIntlActionTypes } from '@/constants/intl';
import type { IIntlStoreState } from '@/interfaces/intl';

const DEFAULT_LOCALE = ELocales.EN;

const initialState: IIntlStoreState = {
	locale: DEFAULT_LOCALE,
};

const setLocale: Reducer<IIntlStoreState, IAction<ELocales>> = (
	state,
	{ payload = DEFAULT_LOCALE }
) => {
	return {
		...state,
		locale: payload,
	};
};

const reducers: ReducersMapObject = {
	[EIntlActionTypes.setLocale]: setLocale,
};

export default createReducer(initialState, reducers);
