import React from 'react';
import { ELocalStorageKey } from '@/constants/storage';
import BasicLayout from '@/layouts/BasicLayout';
import { getLocalStorage } from '@/utils/storage';
import { FormattedMessage } from 'react-intl';
import styles from './AssessmentHasExpired.less';
import { IInvitationBase } from '@/interfaces/invitation';

export const AssessmentHasExpired : React.FC = () => {
	const companyEmail = getLocalStorage<IInvitationBase>(ELocalStorageKey.INVITATION)?.assessment.companyEmail;
	return (
		<BasicLayout>
			<div className={styles.container}>
				<img src="/icons/link_expire.svg" alt="link_expire" className={styles.icon} />
				<div className={styles.linkHasExpired}>
					<FormattedMessage id="assessment.link.expired" />
				</div>
				<div className={styles.contactHR}>
					<FormattedMessage id="assessment.timeout.company.contact" /> { companyEmail }
				</div>
				<div className={styles.contactCustomerService}>
					<FormattedMessage id="assessment.timeout.customer.contact" /> <a href="admin@OAP.com"> admin@OAP.com </a>
				</div>
			</div>
		</BasicLayout>
	);
};

