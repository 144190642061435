import React, { useMemo, useState } from 'react';
import { Row, Col, Modal } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { isNumber } from 'lodash';
import classnames from 'classnames';
import Button from '@/components/Button';
import InfoCard from '../InfoCard';
import { humaniseSeconds } from '@/utils/date';
import type { ReactNode } from 'react';
import type { IAssessmentBase, IFormattedAssessment, IAssessmentDescriptionItem } from '@/interfaces/assessment';
import styles from './AssessmentCover.less';
import LoginModal from '../LoginModal';
import { useHotkeys } from 'react-hotkeys-hook';
import { getCurrentInvitation } from '@/utils/invitation';
import { useHistory } from 'react-router-dom';

const ASSESSMENT_DESCRIPTION: IAssessmentDescriptionItem[] = [
	{
		field: 'questionCount',
		iconSrc: '/icons/question_count.svg',
	},
	{
		field: 'totalPoints',
		iconSrc: '/icons/total_points.svg',
	},
	{
		field: 'duration',
		iconSrc: '/icons/clock.svg',
		formatter: humaniseSeconds,
		defaultValue: <FormattedMessage id="assessment.fields.unlimited" />
	},
];

/**
 * Converts raw assessment data to human-friendly formats.
 * Null values after formatting are omitted.
 * @param assessment Raw assessment data.
 * @returns Formatted assessment data.
 */
const formatAssessmentFields = (assessment: IAssessmentBase): IFormattedAssessment => {
	return ASSESSMENT_DESCRIPTION.reduce((
		formattedAssesment: IFormattedAssessment,
		{ field, formatter, defaultValue }: IAssessmentDescriptionItem,
	) => {
		const fieldValue = assessment[field];
		const formattedValue: ReactNode = fieldValue
			? formatter
				? formatter(fieldValue)
				: fieldValue
			: defaultValue;
		return formattedValue
			? {
					...formattedAssesment,
					[field]: formattedValue,
				}
			: formattedAssesment;
	}, {} as IFormattedAssessment);
};

interface AssessmentCoverProps {
	assessment: IAssessmentBase;
	isResumable: boolean;
	attemptsLeft?: number;
	onStartAssessmentClick: () => void;
	isUrlShared?: boolean;
}

const AssessmentCover: React.FC<AssessmentCoverProps> = (props) => {
	const { assessment, isResumable, attemptsLeft, onStartAssessmentClick, isUrlShared } = props;
	const intl = useIntl();
	const { description } = assessment;
	const [modalStatus, setModalStatus] = useState<boolean>(false);
	const invitation = getCurrentInvitation();
	const endDate = invitation?.assessment.endDate? new Date(invitation?.assessment.endDate) : null;
	const history = useHistory();
	const infoCards = useMemo(() => {
		const formattedAssesment = formatAssessmentFields(assessment);
		return ASSESSMENT_DESCRIPTION.map((item) => (
			<Col key={item.field} className={styles.cardItem}>
				<InfoCard
					icon={<img src={item.iconSrc} alt={item.field} />}
					label={<FormattedMessage id={`assessment.fields.${item.field}`} />}
					value={formattedAssesment[item.field]}
				/>
			</Col>
		));
	}, [assessment]);

	const confirmModal = () => {
		Modal.confirm({
			content: intl.formatMessage({ id: 'assessment.cover.start.confirm' }, { attemptsLeft }),
			onOk: () => onStartAssessmentClick(),
		});
	};

	const showLoginModal = () => {
		setModalStatus(true);
	};

	const hideLoginModal = () => {
		setModalStatus(false);
	};

	const StartHotKey = () => {
		useHotkeys('enter', () => sourceFilter());
	};

	const sourceFilter = () => {
		isUrlShared? showLoginModal() : (isNumber(attemptsLeft)? confirmModal() : onStartAssessmentClick());
	};

	const AssessmentHasExpired = () => {
		history.push('/assessmentHasExpired');
	};

	return (
		<>
			<h2 className={classnames('font-size-24', styles.header)}>
				<FormattedMessage id="assessment.cover.header" values={{ assessmentName: assessment.name }} />
			</h2>
			{description ? (
				<div className={classnames('font-size-16', styles.description)}>
					{description}
				</div>
			) : null}
			<div className={styles.details}>
				<Row
					className={styles.cards}
					gutter={[15, 15]}
				>
					{infoCards}
				</Row>
				<Row className={styles.reminder}>
					<FormattedMessage id="assessment.cover.reminder" />
				</Row>
			</div>
			<div className={styles.buttons}>
				{isResumable ? (
					<Button reverseColor onClick={(endDate!==null && (new Date() >= endDate))? AssessmentHasExpired:onStartAssessmentClick}>
						<FormattedMessage id="assessment.cover.resume" />
					</Button>
				) : (
					<Button reverseColor onClick={(endDate!==null && (new Date() >= endDate))? AssessmentHasExpired:(isUrlShared? showLoginModal : (isNumber(attemptsLeft)? confirmModal : onStartAssessmentClick))}>
						<FormattedMessage id="assessment.cover.start" />
						{StartHotKey()}
					</Button>
				)}
			</div>
			<LoginModal
				visible={modalStatus}
				hideModal={hideLoginModal}
				assessmentId={assessment.assessmentId}
				companyId={assessment.companyId}
			/>
		</>
	);
};

export default AssessmentCover;