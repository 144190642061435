import styles from './Login.less';
import LoginForm from './components/LoginForm';
import classnames from 'classnames';
import UserLayout from '@/layouts/UserLayout';
import { useParams } from 'react-router-dom';

const Login: React.FC= () => {
	const companyId = useParams()['id'];

	return (
		<UserLayout className={styles.page}>
			<div className={classnames('display-flex', 'justify-content-between', 'align-items-center', styles.body)}>
				<div className={styles.leftContent}>
					<div>
						<img src="/icons/login.svg" alt="login" />
					</div>
				</div>
				<LoginForm
					companyId={companyId}
					goTo={'dashboard'}
				/>
			</div>
		</UserLayout>
	);
};

export default Login;