/**
 * Returns the value associated with the given key from local storage.
 * @param key local storage key
 * @returns value associated with the given key, or null if the given key does not exist
 */
export const getLocalStorage = <T>(key: string): T | null => {
	const item = localStorage.getItem(key);
	return item && JSON.parse(item);
};

/**
 * Sets the value with the given key in local storage.
 * @param key local storage key
 * @param value the value to store
 */
export const setLocalStorage = <T>(key: string, value: T): void => {
	localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Removes the key/value pair with the given key from local storage.
 * @param key local storage key
 */
export const removeLocalStorage = (key: string): void => {
	localStorage.removeItem(key);
};

/**
 * Returns the value associated with the given key from session storage.
 * @param key session storage key
 * @returns value associated with the given key, or null if the given key does not exist
 */
export const getSessionStorage = <T>(key: string): T | null => {
	const item = sessionStorage.getItem(key);
	return item && JSON.parse(item);
};

/**
 * Sets the value with the given key in session storage.
 * @param key session storage key
 * @param value the value to store
 */
export const setSessionStorage = <T>(key: string, value: T): void => {
	sessionStorage.setItem(key, JSON.stringify(value));
};

/**
 * Removes the key/value pair with the given key from session storage.
 * @param key session storage key
 */
export const removeSessionStorage = (key: string): void => {
	sessionStorage.removeItem(key);
};
