import { call, put, takeEvery, ForkEffect } from 'redux-saga/effects';
import { setLoading } from '@/store/loading/actions';
import type { IResponseEntity } from '@/utils/request';
import type { IAction } from '@/interfaces/redux';
import { ICandidateBase, IEmailLoginForm, ICheckEmailRequest, ISignupRequest, IVerifyEmailRequest, IMobileLoginForm, ISmsCodeRequest } from '@/interfaces/candidate';
import { ECandidateActionTypes } from '@/constants/candidate';
import { login, mobileLogin, signup, checkExistingEmail, sendEmailCode, verifyEmail, sendMobileCode } from '@/services/candidate';
import { saveCurrentCandidate } from './actions';
import { ELocalStorageKey, ESessionStorageKey } from '@/constants/storage';
import { setLocalStorage, setSessionStorage } from '@/utils/storage';
import { isEmpty } from 'lodash';

function* emailLogin ({ payload }: IAction<IEmailLoginForm>) {
	if (!payload) return;
	yield put(setLoading(ECandidateActionTypes.login, true));
	const responseEntity: IResponseEntity<ICandidateBase> = yield call(login, payload);
	const { response, data, error } = responseEntity;
	if (isEmpty(data) || !data || !response.ok) {
		return;
	}
	if (payload.rememberMe) {
		// eslint-disable-next-line
		const token = response.headers.get('Authorization')!;
		setLocalStorage(ELocalStorageKey.TOKEN, token);
	}
	setSessionStorage(ESessionStorageKey.CANDIDATE, data);
	yield put(saveCurrentCandidate(data));
	yield put(setLoading(ECandidateActionTypes.login, false, error));
}

function* checkEmail ({ payload }: IAction<ICheckEmailRequest>) {
	if (!payload) return;
	yield put(setLoading(ECandidateActionTypes.checkExistingEmail, true));
	const responseEntity: IResponseEntity<boolean> = yield call(checkExistingEmail, payload);
	const { response, data, error } = responseEntity;
	if (response.ok) {
		setSessionStorage(ESessionStorageKey.EXISTING, data);
	}
	yield put(setLoading(ECandidateActionTypes.checkExistingEmail, false, error));
}

function* sendCodeToEmail ({ payload }: IAction<string>) {
	if (!payload) return;
	yield put(setLoading(ECandidateActionTypes.sendEmailCode, true));
	const responseEntity: IResponseEntity<string> = yield call(sendEmailCode, payload);
	const { response, data, error } = responseEntity;
	if (isEmpty(data) || !data || !response.ok) {
		return;
	}
	yield put(setLoading(ECandidateActionTypes.sendEmailCode, false, error));
	return data;
}

function* verifyEmailCode ({ payload }: IAction<IVerifyEmailRequest>) {
	if (!payload) return;
	yield put(setLoading(ECandidateActionTypes.sendEmailCode, true));
	const responseEntity: IResponseEntity<ICandidateBase> = yield call(verifyEmail, payload);
	const { response, error } = responseEntity;
	setSessionStorage(ESessionStorageKey.VERIFIED, response.ok);
	yield put(setLoading(ECandidateActionTypes.verifyEmail, false, error));
	return;
}

function* signUp ({ payload }: IAction<ISignupRequest>) {
	if (!payload) return;
	yield put(setLoading(ECandidateActionTypes.sendEmailCode, true));
	const responseEntity: IResponseEntity<ICandidateBase> = yield call(signup, payload);
	const { response, data, error } = responseEntity;
	if (isEmpty(data) || !data || !response.ok) {
		return;
	}
	setSessionStorage(ESessionStorageKey.REGISTERED, response.ok);
	yield put(setLoading(ECandidateActionTypes.signup, false, error));
	return data;
}

function* mobileSignIn ({ payload }: IAction<IMobileLoginForm>) {
	if (!payload) return;
	yield put(setLoading(ECandidateActionTypes.mobileLogin, true));
	const responseEntity: IResponseEntity<ICandidateBase> = yield call(mobileLogin, payload);
	const { response, data, error } = responseEntity;
	if (isEmpty(data) || !data || !response.ok) {
		return;
	}
	setSessionStorage(ESessionStorageKey.CANDIDATE, data);
	yield put(saveCurrentCandidate(data));
	yield put(setLoading(ECandidateActionTypes.mobileLogin, false, error));
}

function* sendCodeToPhone ({ payload }: IAction<ISmsCodeRequest>) {
	if (!payload) return;
	yield put(setLoading(ECandidateActionTypes.sendMobileCode, true));
	const responseEntity: IResponseEntity<string> = yield call(sendMobileCode, payload);
	const { response, data, error } = responseEntity;
	if (isEmpty(data) || !data || !response.ok) {
		return;
	}
	yield put(setLoading(ECandidateActionTypes.sendMobileCode, false, error));
	return data;
}

export default function* watchCandidate(): Generator<ForkEffect<never>, void, unknown> {
	yield takeEvery(ECandidateActionTypes.login, emailLogin);
	yield takeEvery(ECandidateActionTypes.checkExistingEmail, checkEmail);
	yield takeEvery(ECandidateActionTypes.sendEmailCode, sendCodeToEmail);
	yield takeEvery(ECandidateActionTypes.verifyEmail, verifyEmailCode);
	yield takeEvery(ECandidateActionTypes.signup, signUp);
	yield takeEvery(ECandidateActionTypes.mobileLogin, mobileSignIn);
	yield takeEvery(ECandidateActionTypes.sendMobileCode, sendCodeToPhone);
}